<!-- <app-loader></app-loader> -->
<div class="full-body">
  <!---->
  <nav class="navbar navbar-expand-lg navbar-light bg-light">
    <div class="container-fluid">
      <div class="d-flex">
        <button
          type="button"
          id="sidebarCollapse"
          class="btn text-sidebar bg-turbo-yellow menu-toggle-btn"
        >
          ☰
        </button>
        <div>
          <h6>Expired Products</h6>
          <p>Inventory Management <i class="fa fa-angle-right"></i> Expired</p>
        </div>
      </div>
      <button
        class="btn btn-dark d-inline-block d-lg-none ml-auto"
        type="button"
        data-toggle="collapse"
        data-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <i class="fas fa-align-justify"></i>bb
      </button>
    </div>
  </nav>
  <!---->
  <div class="search-row">
    <div class="flex-1">
      <h6>All Products</h6>
    </div>
   </div>
    <!---->
    <div class="content table-responsive table-full-width">
        <table class="table table-hover table-striped">
          <thead>
            <tr>
              <th>Bag ID</th>
              <th>Blood Type</th>
              <th>Genotype</th>
              <th>Date</th>
              <th>Time</th>
              <th>Expiration Date</th>
              <th>Type</th>
            </tr>
          </thead>
          <tbody>
            <tr
            >
              <td>1 </td>
              <td>O+</td>
              <td>AA</td>
              <td>2024-04-03 </td>
              <td>17:11:31</td>
              <td>2024-04-03</td>
              <td>internal</td>
            </tr>
          </tbody>
        </table>
      </div>
  <!---->
</div>
