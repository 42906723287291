import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DonationService } from 'src/app/services/donation.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-screening',
  templateUrl: './screening.component.html',
  styleUrls: ['./screening.component.scss']
})
export class ScreeningComponent implements OnInit {

  donations = []
  filteredDonations = []
  currentPage = 1
  p = 1
  testForm:FormGroup
  selectedDonationID
  selectedBagID
  invalidFields:boolean = false
  invalidReason:boolean = false
  loading:boolean = false
  saving:boolean = false

  bloodTypes = [
    {name:'A+'},
    {name:'A-'},
    {name:'B+'},
    {name:'B-'},
    {name:'AB+'},
    {name:'AB-'},
    {name:'O+'},
    {name:'O-'},
  ]

  genoTypes = [
    {name:'AA'},
    {name:'AS'},
    {name:'SS'},
    {name:'AC'}
  ]

  constructor(
    private modalService:NgbModal,
    private fb:FormBuilder,
    private donationService:DonationService
  ) { 
  }

  ngOnInit(): void {
    this.getTestingStats()
    this.setTestForm()
  }

  setTestForm(){
    this.testForm = this.fb.group({
      donation_id:[],
      bag_id:[],
      condition:['good',Validators.required],
      hiv:['-ve',Validators.required],
      hepatitis_b:['-ve',Validators.required],
      hepatitis_c:['-ve',Validators.required],
      vdlr:['',Validators.required],
      malaria:['',Validators.required],
      blood_type:['',Validators.required],
      genotype:['',Validators.required],
      reason:['']
    })
  }

  open(content){
    this.modalService.open(content,{centered:true})
  }

  openTestModal(modal, donationID, bagID){
    this.open(modal)
    this.selectedDonationID = donationID
    this.selectedBagID = bagID
  }

  openComingSoonModal(modal){
    this.open(modal)
  }

  uploadTest(){
    this.invalidFields = false
    this.invalidReason = false
    this.testForm.value.donation_id = this.selectedDonationID
    this.testForm.value.bag_id = this.selectedBagID
    if(this.testForm.invalid){
      this.invalidFields = true
      return
    }
    else{
      if(this.testForm.value.condition == 'bad' && (!this.testForm.value.reason.trim())){
        this.invalidReason = true
        return
      }
      else{
        this.saving = true
        this.donationService.uploadResult(this.testForm.value).subscribe(data=>{
          this.getTestingStats()
          this.modalService.dismissAll()
          this.saving = false
          Swal.fire(
            'Success',
            'Test result has been uploaded',
            'success'
          )
        },
        err=>{
          this.modalService.dismissAll()
          this.saving = false
          Swal.fire(
            'Oops',
            'Something went wrong',
            'error'
          )
        })
      }
    }
  }

  checkBloodStatus(){
    // console.log(this.testForm.value.condition)
  }

  getTestingStats(){
    this.loading = true
    this.donationService.getTestingStats().subscribe((data:any)=>{
      if(typeof data?.description == 'string'){
        this.donations = []
        this.loading = false
        return
      }
      this.donations = data.test_values
      this.filteredDonations = [...this.donations]
      this.loading = false
    },
      err=>{
        this.loading = false
      })
  }


}
