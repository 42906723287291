import { Component, OnInit } from '@angular/core';
import { DonationService } from '../../services/donation.service';
import { DonorService } from '../../services/donor.service';

@Component({
  selector: 'app-donor-breakdown',
  templateUrl: './donor-breakdown.component.html',
  styleUrls: ['./donor-breakdown.component.scss']
})
export class DonorBreakdownComponent implements OnInit {

  loading:boolean = false
  dailyDonorsSignup = []
  donorsBreakdown = []
  currentPage = 1
  p = 1
  isDaily:boolean = true
  isWeekly:boolean = false
  isMonthly:boolean = false
  dateTime = ''
  donors = []

  constructor(
    private donationService:DonationService,
    private donorService:DonorService
  ) { }

  ngOnInit(): void {
    this.getDonorDailySignup()
    this.getDonorMonthlySignup()
    this.getAllDonors()
    let d = new Date()
    this.dateTime = `
    ${d.getDate()}-${d.getMonth()+1}-${d.getFullYear()} ${d.getHours()}:${d.getMinutes()}`
  }

  refresh(){
    this.ngOnInit()
  }

  getDonorMonthlySignup(){
    this.donationService.getDonorSignupBreakdown().subscribe(data=>{
      let res = <any>data
      this.donorsBreakdown = res.signups
      this.donorsBreakdown.reverse()
    },
      err=>{
        console.log(err)
      })
  }

  getDonorDailySignup(){
    this.donationService.getDonorsSignupDailyBreakdown().subscribe(data=>{
      let res:any = data
      this.dailyDonorsSignup = res.signups
      this.dailyDonorsSignup.forEach(res=>{
        let date = new Date(`${res.mon}/${res.daily}/${res.yr} 12:00`)
        res.virtualTimeStamp = date.getTime()
      })
      this.dailyDonorsSignup.sort((a,b) => (a.virtualTimeStamp < b.virtualTimeStamp) ? 1 : ((b.virtualTimeStamp < a.virtualTimeStamp) ? -1 : 0)); 
    },
      err=>{
        console.log(err)
      })
  }

  getAllDonors(){
    this.donorService.getAllDonors().subscribe((data:any)=>{
      this.donors = [...data.users]
    },
      err=>{

      })
  }

  getMonthName(number){
    let monthArray = ["Jan","Feb","March","April","May","Jun","July","Aug","Sept","Oct","Nov","Dec"]
    return monthArray[number-1]
  }

  displayDaily(){
    this.isDaily = true
    this.isWeekly = false
    this.isMonthly = false
  }

  displayWeekly(){
    
  }

  displayMonthly(){
    this.isDaily = false
    this.isWeekly = false
    this.isMonthly = true
  }

}
