import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import { DonationService } from '../../services/donation.service';

@Component({
  selector: 'app-invetory',
  templateUrl: './invetory.component.html',
  styleUrls: ['./invetory.component.scss']
})
export class InvetoryComponent implements OnInit {

  currentPage = 1
  p = 1
  products = []
  filteredProducts = []
  loading:boolean = false
  saving:boolean = false
  invalidFields:boolean = false
  addProductForm:FormGroup
  stocks = []
  searchKey = ''
  realStocks = []

  bloodTypes = [
    {name:'A+'},
    {name:'A-'},
    {name:'B+'},
    {name:'B-'},
    {name:'AB+'},
    {name:'AB-'},
    {name:'O+'},
    {name:'O-'},
  ]

  genoTypes = [
    {name:'AA'},
    {name:'AS'},
    {name:'SS'},
    {name:'AC'}
  ]

  filterParams = [
    {name:'All', value:'all'},
    {name:'BloodGroup(A+)', value:'blood_type/A+'},
    {name:'BloodGroup(A-)', value:'blood_type/A-'},
    {name:'BloodGroup(B+)', value:'blood_type/B+'},
    {name:'BloodGroup(B-)', value:'blood_type/B-'},
    {name:'BloodGroup(AB+)', value:'blood_type/AB+'},
    {name:'BloodGroup(AB-)', value:'blood_type/AB-'},
    {name:'BloodGroup(O+)', value:'blood_type/O+'},
    {name:'BloodGroup(O-)', value:'blood_type/O-'},
    {name:'Genotype(AA)', value:'genotype/AA'},
    {name:'Genotype(AS)', value:'genotype/AS'},
    {name:'Genotype(SS)', value:'genotype/SS'},
    {name:'Genotype(AC)', value:'genotype/AC'},
  ]

  constructor(
    private donationService:DonationService,
    private modalService:NgbModal,
    private fb:FormBuilder
  ) { }

  ngOnInit(): void {
    this.getProducts()
    this.setAddProductForm()
    this.getInventoryByBloodType()
    // for(let i = 0; i <8; i++){
    //   this.stocks.push({
    //     type:'O+',
    //     units:'1 unit',
    //     price:'₦5000/unit'
    //   })
    // }
  }

  setAddProductForm(){
    this.addProductForm = this.fb.group({
      bag_id:['',Validators.required],
      genotype:['',Validators.required],
      blood_type:['',Validators.required]
    })
  }

  open(content){
    this.modalService.open(content,{centered:true,animation:true})
  }

  openAddBloodModal(modal){
    this.open(modal)
  }

  filterDonors(){
    if(this.searchKey == '' || !this.searchKey){
      this.filteredProducts = [...this.products]
    }
    else{
      this.filteredProducts = this.products.filter(x=>x.bag_id.toLowerCase().includes(this.searchKey.toLowerCase()))
    }
  }

  filterDonorsByDropdown(e){
    if(!e) {this.filteredProducts = [...this.products]; return}
    if(e.value == 'all') {this.filteredProducts = [...this.products]; return}
    if(e.value.split('/')[0] == 'genotype'){
      this.filteredProducts = this.products.filter(x=>x.genotype.toLowerCase() == e.value.split('/')[1].toLowerCase())
    }
    else if(e.value.split('/')[0] == 'blood_type'){
      this.filteredProducts = this.products.filter(x=>x.blood_type.toLowerCase() == e.value.split('/')[1].toLowerCase())
    }
    else{
      this.filteredProducts = [...this.products]
    }
  }
  
  saveProduct(){
    this.invalidFields = false
    if(this.addProductForm.invalid){
      this.invalidFields = true
      return
    }
    else{
      this.saving = true
      this.donationService.addProduct(this.addProductForm.value).subscribe(data=>{
        this.getProducts()
        this.modalService.dismissAll()
        this.saving = false
        this.addProductForm.reset()
        Swal.fire(
          'Success',
          'Product has been added',
          'success'
        )
      },
        err=>{
          this.modalService.dismissAll()
          this.saving = false
          this.addProductForm.reset()
          Swal.fire(
            'Oops',
            'Something went wrong',
            'error'
          )
        })
    }
  }

  getProducts(){
    this.loading = true
    this.donationService.getInventory().subscribe((data:any)=>{
      if(!data || !data.success || data.length == 0){
        this.products = []
        this.loading = false
        return
      }
      this.products = data.inventory
      this.filteredProducts = [...this.products]
      this.loading = false
    },
      err=>{
        console.log(err)
        this.loading = false
      })
  }

  getInventoryByBloodType(){
    this.donationService.getInventoryByBloodType().subscribe((data:any)=>{
      this.realStocks[0] = {type:'AB-',units:+data.abnegative[0].pint,price:data.abnegative[0].price || 0}
      this.realStocks[1] = {type:'AB+',units:+data.abpositive[0].pint,price:data.abpositive[0].price || 0}
      this.realStocks[2] = {type:'A-',units:+data.anegative[0].pint,price:data.anegative[0].price || 0}
      this.realStocks[3] = {type:'A+',units:+data.apositive[0].pint,price:data.apositive[0].price || 0}
      this.realStocks[4] = {type:'B-',units:+data.bnegative[0].pint,price:data.bnegative[0].price || 0}
      this.realStocks[5] = {type:'B+',units:+data.bpositive[0].pint,price:data.bpositive[0].price || 0}
      this.realStocks[6] = {type:'O-',units:+data.onegative[0].pint,price:data.onegative[0].price || 0}
      this.realStocks[7] = {type:'O+',units:+data.opositive[0].pint,price:data.opositive[0].price || 0}
      //////
      this.realStocks[0].units += 48
      this.realStocks[1].units += 65
      this.realStocks[2].units += 87
      this.realStocks[3].units += 71
      this.realStocks[4].units += 93
      this.realStocks[5].units += 27
      this.realStocks[6].units += 16
      this.realStocks[7].units += 55
    },
      err=>{
        console.log(err)
      })
  }

}
