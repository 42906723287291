import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DonationService } from 'src/app/services/donation.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.scss']
})
export class UploadComponent implements OnInit {
  products = ['hiv', 'hepatitis_b', 'hepatitis_c', 'vdlr', 'malaria'];
  results = ['Reactive', 'Non-Reactive'];
  blood_types = ['A+', 'A-', 'B+', 'B-', 'AB+', 'AB-', 'O+', 'O-'];
  genotypes = ['AA', 'AS', 'AC', 'SS'];

  newItem = { 
    product: '', 
    result: '', 
    blood_types: '', 
    genotype: '', 
    condition: 'good', 
    reason: '', 
    donation_id: '', 
    bag_id: ''
  };
  invalidReason = false;

  inventory = [];

  constructor(
    private donationService: DonationService, 
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.newItem.donation_id = params['donation_id'] || '';
      this.newItem.bag_id = params['bag_id'] || '';
    });
  }

  onProductChange() {
    this.newItem.result = '';
    this.newItem.blood_types = '';
    this.newItem.genotype = '';
  }

  checkBloodStatus() {
    this.invalidReason = this.newItem.condition === 'bad' && !this.newItem.reason;
  }

  addItem() {
    if (this.newItem.product && this.newItem.result && this.newItem.blood_types && this.newItem.genotype && (this.newItem.condition === 'good' || (this.newItem.condition === 'bad' && this.newItem.reason))) {
      this.inventory.push({ ...this.newItem });
      this.newItem = { 
        product: '', 
        result: '', 
        blood_types: '', 
        genotype: '', 
        condition: 'good', 
        reason: '', 
        donation_id: this.newItem.donation_id, 
        bag_id: this.newItem.bag_id 
      };
      this.invalidReason = false;
    } else {
      this.invalidReason = true;
    }
  }

  removeItem(index: number) {
    this.inventory.splice(index, 1);
  }

  updateInventory() {
    const payload = this.inventory.map(item => ({
      product: item.product,
      result: item.result,
      blood_types: item.blood_types,
      genotype: item.genotype,
      condition: item.condition,
      reason: item.reason,
      donation_id: item.donation_id,
      bag_id: item.bag_id
    }));

    this.donationService.uploadResult(payload).subscribe(
      (response) => {
        Swal.fire('Success', 'Inventory updated successfully!', 'success');
        console.log(response);
      },
      (error) => {
        Swal.fire('Error', 'Failed to update inventory', 'error');
        console.log(error);
      }
    );
  }
}


