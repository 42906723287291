import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class OrdersService {

  bankxApiUrl = environment.bankXAPiUrl
  smartBankApiUrl = environment.smartCenterApiUrl

  constructor(
    private httpClient:HttpClient
  ) { }

  getCurrentOrders(memberid){
    return this.httpClient.get(`${this.bankxApiUrl}bankx/api/v1/orders/current/${memberid}`)
  }

  getOrderHistory(memberid){
    return this.httpClient.get(`${this.bankxApiUrl}bankx/api/v1/orders/history/${memberid}`)
  }

  confirmOrder(bag_id){
    return this.httpClient.post(`${this.smartBankApiUrl}manage/confirm/order`, {bag_id})
  }

  thankDonor2(order_id){
    return this.httpClient.post(`https://lab.lifebank.ke/bankx/api/v1/orders/history/thanks`,{order_id})
  }
}
