import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import { DonorService } from '../../services/donor.service'
import { DonationService } from '../../services/donation.service';
import { ExcelService } from '../../services/excel.service';


@Component({
  selector: 'app-donors',
  templateUrl: './donors.component.html',
  styleUrls: ['./donors.component.scss']
})
export class DonorsComponent implements OnInit {

  addDonorform:FormGroup
  donationForm:FormGroup
  donors = []
  filteredDonors = []
  currentPage = 1
  p = 1
  invalidDetails:boolean = false
  donationInvalidDetails:boolean = false
  saving:boolean = false
  savingDonation:boolean = false
  loading:boolean = false
  searchKey = ''
  selectedDonorID
  isTableView:boolean = true
  fetchingDonorsContacts:boolean = false
  donorsContactArray = []




  bloodTypes = [
    {name:'A+'},
    {name:'A-'},
    {name:'B+'},
    {name:'B-'},
    {name:'AB+'},
    {name:'AB-'},
    {name:'O+'},
    {name:'O-'},
    {name:'I don\'t know'}
  ]
  gender = [
    {name:'male'},
    {name:'female'}
  ]

  filterParams = [
    {name:'All', value:'all'},
    {name:'Gender(Male)', value:'gender/male'},
    {name:'Gender(Female)', value:'gender/female'},
    {name:'A+', value:'blood_type/A+'},
    {name:'A-', value:'blood_type/A-'},
    {name:'B+', value:'blood_type/B+'},
    {name:'B-', value:'blood_type/B-'},
    {name:'AB+', value:'blood_type/AB+'},
    {name:'AB-', value:'blood_type/AB-'},
    {name:'O+', value:'blood_type/O+'},
    {name:'O-', value:'blood_type/O-'},
  ]

  useAutoGeneratedBagID:boolean = false
  generatedBagID = ''
  generatingBagID:boolean = false

  constructor(
    private modalService:NgbModal,
    private fb:FormBuilder,
    private donorService:DonorService,
    private donationService:DonationService,
    private excelService:ExcelService
  ) { 
    this.setFormValues()
    this.setDonationFormValues()
  }

  ngOnInit(): void {
    this.getAllDonors()
    this.getDonorsNumbersandEmails()
  }

  setFormValues(){
    this.addDonorform = this.fb.group({
      first_name:['',Validators.required],
      last_name:['',Validators.required],
      blood_type:['',Validators.required],
      phone:['',Validators.required],
      state:['',Validators.required],
      city:['',Validators.required],
      email:['',Validators.required],
      gender:['',Validators.required],
      password:["123456"],
      ex_code:['']
    })
  }

  setDonationFormValues(){
    this.donationForm = this.fb.group({
      donor_id:[''],
      weight:['',Validators.required],
      blood_pressure:['',Validators.required],
      haemoglobin:['',Validators.required],
      bag_id:['']
    })
  }


  open(content){
    this.modalService.open(content,{centered:true,animation:true})
  }

  addDonor(modal){
    this.open(modal)
  }

  startDonation(modal, donorID){
    this.open(modal)
    this.selectedDonorID = donorID
  }

  toggleAutoGenerateBagID(){
    if(this.useAutoGeneratedBagID){
      this.generateBagNo()
    }
  }

  generateBagNo(){
    this.generatingBagID = true
    this.donationService.generateBagNo().subscribe((data:any)=>{
      this.donationForm.value.bag_id = data.bag_id
      this.generatedBagID = data.bag_id
      this.generatingBagID = false
    },
      err=>{
        console.log(err)
      })
  }

  filterDonors(){
    if(this.searchKey == '' || !this.searchKey){
      this.filteredDonors = [...this.donors]
    }
    else{
      this.filteredDonors = this.donors.filter(x=>`${x.first_name.toLowerCase()} ${x.last_name.toLowerCase()}`.includes(this.searchKey))
    }
  }

  filterDonorsByDropdown(e){
    if(!e) {this.filteredDonors = [...this.donors]; return}
    if(e.value == 'all') {this.filteredDonors = [...this.donors]; return}
    if(e.value.split('/')[0] == 'gender'){
      this.filteredDonors = this.donors.filter(x=>x.gender.toLowerCase() == e.value.split('/')[1].toLowerCase())
    }
    else if(e.value.split('/')[0] == 'blood_type'){
      this.filteredDonors = this.donors.filter(x=>x.blood_type.toLowerCase() == e.value.split('/')[1].toLowerCase())
    }
    else{
      this.filteredDonors = [...this.donors]
    }
  }

  saveDonation(){
    this.donationInvalidDetails = false
    this.donationForm.value.donor_id = this.selectedDonorID
    if(this.useAutoGeneratedBagID){
      this.donationForm.value.bag_id = this.generatedBagID
    }
    if(this.donationForm.invalid){
      this.donationInvalidDetails = true
      return
    }
    else{
      this.savingDonation = true
      this.donationService.makeDonation(this.donationForm.value).subscribe(data=>{
        this.modalService.dismissAll()
        Swal.fire(         
          'Success',
          `Donation has been saved`,
          'success'
        )
      },
        err=>{
          this.modalService.dismissAll()
          Swal.fire(         
            'Oops',
            `Something went wrong`,
            'error'
          )
        })
    }
  }

  save(){
    this.invalidDetails = false
    if(this.addDonorform.invalid){
      this.invalidDetails = true
      return
    }
    else{
      this.saving = true
      // console.log(this.addDonorform.value)
      // return
      this.donorService.signupUser(this.addDonorform.value).subscribe(data=>{
        this.modalService.dismissAll()
        let res = <any>data
        this.getAllDonors()
        this.saving = false
        this.addDonorform.reset()
        this.setFormValues()
        if(res.status == "error"){
          Swal.fire(
            'Oops!',
            `${res.message}`,
            'error'
          )
        }
        else if(res.ok){
          Swal.fire(
            'Success!',
            `Donor has been added`,
            'success'
          )
        }
        else{
          Swal.fire(
            'Success',
            `Donor has been added`,
            'success'
          )
        }
      },
        err=>{
          this.modalService.dismissAll()
          let res2 = <any>err
          this.saving = false
          this.addDonorform.reset()
          this.setFormValues()
          if(res2.error){
            if(res2.error.message){
              Swal.fire(
                'Oops!',
                `${res2.error.message}`,
                'error'
              )
            }
          }
          else{
            Swal.fire(
              'Oops!',
              `Something went wrong`,
              'error'
            )
          }
        })
    }
  }

  getAllDonors(){
    this.loading = true
    this.donorService.getAllDonors().subscribe(data=>{
      let res = <any>data
      this.donors = [...res.users]
      this.filteredDonors = [...res.users]
      this.loading = false
    },
      err=>{
        this.loading = false
      })
  }

  getDonorsNumbersandEmails(){
    this.fetchingDonorsContacts = true
    this.donorService.getDonorsNumbersandEmails().subscribe(data=>{
      // console.log(data)
      let res = <any>data
      this.donorsContactArray = res.donors
      this.fetchingDonorsContacts = false
    },
      err=>{
        console.log(err)
      })
  }

  exportAsXLSX():void {
    let newDonorsArray = []
    this.donorsContactArray.forEach(donor=>{
      newDonorsArray.push({
        name:`${donor.first_name} ${donor.last_name}`,
        phone:`${donor.phone}`,
        email:`${donor.email}`
      })
    })
    this.excelService.exportAsExcelFile(newDonorsArray, 'DonorCare');
  }


}
