<!-- <div id="mySidebar" class="sidebar">
    <a href="javascript:void(0)" class="closebtn" (click)="closeNav()">×</a>
    <a href="javascript:void(0)" class="open-btn" (click)="openNav()">☰</a>
    <p [routerLink]="['/main/donors']" class="cursor-pointer menu-name">Donors</p>
    <p [routerLink]="['/main/donations']" class="cursor-pointer menu-name">Donations</p>
    <p [routerLink]="['/main/screening']" class="cursor-pointer menu-name">Screening</p>
    <p [routerLink]="['/main/inventory']" class="cursor-pointer menu-name">Storage</p>
    <p class="cursor-pointer menu-name">Logout</p>
</div>

<div id="main">

    <router-outlet></router-outlet>

</div> -->

<div class="wrapper">
    <!-- Sidebar  -->
    <nav id="sidebar">
        <div class="sidebar-header">
            <!-- <h3>Dacor Sidebar</h3> -->
            <img src="../../../assets/img/lifebank5.png" height="30px">
        </div>

        <ul class="list-unstyled components">
            <p>SmartBank</p>
            <li [ngClass]="{'active': currentUrl == '/main/dashboard'}">
                <a [routerLink]="['/main/dashboard']"><i class="fa fa-dashboard mr-2"></i> Dashboard</a>
            </li>
            <li>
                <a href="#pageSubmenu" data-toggle="collapse" aria-expanded="false" class="dropdown-toggle"><i class="fa fa-heart mr-2"></i> Donor Management</a>
                <ul class="collapse list-unstyled" id="pageSubmenu">
                    <li>
                        <a [routerLink]="['/main/donors']">View Donors</a>
                        <!-- <a (click)="openPrivacyModal(privacyModal)">View Donors</a> -->
                    </li>
                    <li>
                        <a [routerLink]="['/main/donors-breakdown']">Donors Breakdown</a>
                    </li>
                </ul>
            </li>            
            <li [ngClass]="{'active': currentUrl == '/main/donations'}">
                <a [routerLink]="['/main/donations']"><i class="fa fa-heart mr-2"></i> Donations</a>
            </li>
            <li [ngClass]="{'active': currentUrl == '/main/screening'}">
                <a [routerLink]="['/main/screening']"><i class="fa fa-tachometer mr-2"></i> Screening</a>
            </li>
            <li [ngClass]="{'active': currentUrl == '/main/inventory'}">
                <a [routerLink]="['/main/inventory']"><i class="fa f-houzz fa-cube mr-2"></i> Inventory</a>
            </li>
            <li [ngClass]="{'active': currentUrl == '/main/appointments'}">
                <a [routerLink]="['/main/appointments']"><i class="fa fa-clock-o mr-2"></i> Appointments</a>
            </li>
            <li [ngClass]="{'active': currentUrl == '/main/orders'}">
                <a [routerLink]="['/main/orders']"><i class="fa fa-first-order mr-2"></i> Orders</a>
            </li>
            <!-- <li class="activ">
                <a href="#homeSubmenu" data-toggle="collapse" aria-expanded="false" class="dropdown-toggle">Home</a>
                <ul class="collapse list-unstyled" id="homeSubmenu">
                    <li>
                        <a href="#">Home 1</a>
                    </li>
                    <li>
                        <a href="#">Home 2</a>
                    </li>
                    <li>
                        <a href="#">Home 3</a>
                    </li>
                </ul>
            </li>
            <li>
                <a href="#">About</a>
            </li> -->
            <!-- <li>
                <a href="#pageSubmenu" data-toggle="collapse" aria-expanded="false" class="dropdown-toggle">Pages</a>
                <ul class="collapse list-unstyled" id="pageSubmenu">
                    <li>
                        <a href="#">Page 1</a>
                    </li>
                    <li>
                        <a href="#">Page 2</a>
                    </li>
                    <li>
                        <a href="#">Page 3</a>
                    </li>
                </ul>
            </li> -->
        </ul>

        <!-- <ul class="list-unstyled CTAs">
            <li>
                <a href="#" class="download">Download source</a>
            </li>
            <li>
                <a href="https://bootstrapious.com/p/bootstrap-sidebar" class="article">Back to article</a>
            </li>
        </ul> -->
        <div class="nav-profile dropup">
            <div class="parent-flex dropdown-toggle" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <div class="flex-1">
                    <h1><i class="fa fa-user-circle"></i></h1>
                </div>
                <div class="flex-2">
                    <h6>SmartBank User</h6>
                    <p>Super Admin</p>
                </div>
            </div>
            <div class="dropdown-menu cursor-pointer" aria-labelledby="dropdownMenuButton">
                <!-- <a class="dropdown-item">View Profile</a>
                <a class="dropdown-item">Settings</a> -->
                <a class="dropdown-item" (click)="logout()">Logout</a>
            </div>
        </div>
    </nav>

    <!-- Page Content  -->
    <div id="content">

        <!-- <nav class="navbar navbar-expand-lg navbar-light bg-light">
            <div class="container-fluid">

                <button type="button" id="sidebarCollapse" class="btn text-sidebar bg-turbo-yellow">
                    ☰
                    <span></span>
                </button>
                <button class="btn btn-dark d-inline-block d-lg-none ml-auto" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <i class="fas fa-align-justify"></i>bb
                </button>

                <div class="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul class="nav navbar-nav ml-auto">
                        <li class="nav-item active">
                            <a class="nav-link" href="#">Page</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="#">Page</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="#">Page</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="#">Page</a>
                        </li>
                    </ul>
                </div>
            </div>
        </nav> -->

        <router-outlet></router-outlet>

    </div>
</div>

<ng-template #privacyModal>
    <div class="privacy-modal">
        <h1><i class="fa fa-info-circle"></i></h1>
        <h3>Data Privacy</h3>
        <p>Not allowed</p>
    </div>
</ng-template>
