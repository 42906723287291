import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  smartCenterApiUrl = environment.smartCenterApiUrl
  bankXApiUrl = environment.bankXAPiUrl

  constructor(
    private httpClient:HttpClient
  ) { }

  login(obj){
    // return this.httpClient.post(`${this.smartCenterApiUrl}authentication/login`,obj)
    return this.httpClient.post(`${this.bankXApiUrl}bankx/api/v1/auth/login/`,obj)
  }
}
