<app-loader *ngIf="loading"></app-loader>
<div class="full-body">
    <!---->
    <nav class="navbar navbar-expand-lg navbar-light bg-light">
        <div class="container-fluid">
            <div class="d-flex">
                <button type="button" id="sidebarCollapse" class="btn text-sidebar bg-turbo-yellow menu-toggle-btn">
                    ☰
                </button>
                <div>
                    <h6>Donor Management</h6>
                    <p>Home <i class="fa fa-angle-right ml-2 mr-2"></i> Donor Management <i class="fa fa-angle-right ml-2 mr-2"></i> Donor Breakdown</p>
                </div>
            </div>
            <button class="btn btn-dark d-inline-block d-lg-none ml-auto" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <i class="fas fa-align-justify"></i>bb
            </button>

        </div>
    </nav>
    <!---->
    <div class="details-body">
        <div class="top-card">
            <div>
                <h3>Donor Signup Breakdown</h3>
                <p>Last Updated {{dateTime}} <button class="btn btn-refresh ml-4" (click)="refresh()"><i class="fa fa-refresh"></i> Refresh</button></p>
            </div>
            <div>
                <h1 *ngIf="donors.length<1">0</h1>
                <h1 *ngIf="donors.length>0">{{donors.length + 7575}}</h1>
                <p><b>Total Donors</b></p>
            </div>
        </div>
        <!---->
        <div>
            <div class="tab-title">
                <p class="btn btn-daily" [ngClass]="{'active-tab-title':isDaily}" (click)="displayDaily()">Daily</p>
                <p class="btn btn-weekly" (click)="displayWeekly()">Weekly</p>
                <p class="btn btn-monthly" [ngClass]="{'active-tab-title':isMonthly}" (click)="displayMonthly()">Monthly</p>
            </div>
        </div>
        <!---->
        <div class="table-head-top">
            <h5 *ngIf="isDaily">Daily Breakdown</h5>
            <h5 *ngIf="isMonthly">Monthly Breakdown</h5>
            <div class="form-group">
                <label class="form-label">Filter By Date</label>
                <input type="date" class="form-control">
            </div>
        </div>
        <!---->
        <div class="content table-responsive table-full-width" *ngIf="isDaily">
            <table class="table table-hover table-striped">
                    <thead>
                        <tr>
                            <th>Year</th>
                            <th>Month</th>
                            <th>Day</th>
                            <th>Num</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let signup of dailyDonorsSignup | paginate: { itemsPerPage: 20, currentPage: p }">
                            <td>{{signup.yr}}</td>
                            <td>{{getMonthName(signup.mon)}}</td>
                            <td>{{signup.daily}}</td>
                            <td>{{signup.num}}</td>
                        </tr>
                    </tbody>
            </table>
            <div style="display: flex; justify-content: flex-end;">
                <div>
                    <pagination-controls (pageChange)="p = $event"></pagination-controls>                            
                </div>
            </div>
        </div>

        <!---->
        <div class="content table-responsive table-full-width" *ngIf="isMonthly">
            <table class="table table-hover table-striped">
                    <thead>
                        <tr>
                            <th>Year</th>
                            <th>Month</th>
                            <th>Num</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let signup of donorsBreakdown | paginate: { itemsPerPage: 20, currentPage: p }">
                            <td>{{signup.yr}}</td>
                            <td>{{getMonthName(signup.mon)}}</td>
                            <td>{{signup.num}}</td>
                        </tr>
                    </tbody>
            </table>
            <div style="display: flex; justify-content: flex-end;">
                <div>
                    <pagination-controls (pageChange)="p = $event"></pagination-controls>                            
                </div>
            </div>
        </div>

    </div>
</div>