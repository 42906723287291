import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './components/login/login.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MainComponent } from './components/main/main.component';
import { DonorsComponent } from './components/donors/donors.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { DonationsComponent } from './components/donations/donations.component';
import { ScreeningComponent } from './components/screening/screening.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { SmallLoaderComponent } from './components/small-loader/small-loader.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { LoaderComponent } from './components/loader/loader.component';
import { InvetoryComponent } from './components/invetory/invetory.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { DonorBreakdownComponent } from './components/donor-breakdown/donor-breakdown.component';
import { AppointmentsComponent } from './components/appointments/appointments.component';
import { OrdersComponent } from './components/orders/orders.component';
import { ExpiredComponent } from './components/expired/expired.component';
import { UploadComponent } from './components/upload/upload.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    MainComponent,
    DonorsComponent,
    DonationsComponent,
    ScreeningComponent,
    SmallLoaderComponent,
    LoaderComponent,
    InvetoryComponent,
    DashboardComponent,
    DonorBreakdownComponent,
    AppointmentsComponent,
    OrdersComponent,
    ExpiredComponent,
    UploadComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    NgxPaginationModule,
    NgSelectModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
