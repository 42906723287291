import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GeneralService } from './general.service';

@Injectable({
  providedIn: 'root'
})
export class DonationService {

  constructor(
    private httpClient:HttpClient,
    private generalService:GeneralService
  ) { }

  makeDonation(obj){
    return this.httpClient.post(`${this.generalService.smartCenterApiUrl}manage/donate`, obj)
  }

  getDonationStats(){
    return this.httpClient.get(`${this.generalService.smartCenterApiUrl}manage/donation/stats`)
  }

  moveToTest(obj){
    return this.httpClient.post(`${this.generalService.smartCenterApiUrl}manage/status/test`, obj)
  }

  getTestingStats(){
    return this.httpClient.get(`${this.generalService.smartCenterApiUrl}manage/testing/stats`)
  }

  uploadResult(obj){
    return this.httpClient.post(`${this.generalService.smartCenterApiUrl}manage/upload/results`, obj)
  }

  getInventory(){
    return this.httpClient.get(`${this.generalService.smartCenterApiUrl}manage/inventory/stats`)
  }

  addProduct(obj){
    return this.httpClient.post(`${this.generalService.smartCenterApiUrl}manage/inventory/add`, obj)
  }

  filterDonationsByDate(obj){
    return this.httpClient.post(`${this.generalService.smartCenterApiUrl}manage/donation/date`, obj)
  }

  getInventoryByBloodType(){
    return this.httpClient.get(`${this.generalService.smartCenterApiUrl}manage/types/blood`)
  }

  getDonorSignupBreakdown(){
    return this.httpClient.get(`${this.generalService.donorCareApiUrl}super_admin/signups`)
  }

  getDonorsSignupDailyBreakdown(){
    return this.httpClient.get(`${this.generalService.donorCareApiUrl}super_admin/daily`)
  }

  generateBagNo(){
    return this.httpClient.get(`${this.generalService.smartCenterApiUrl}manage/generate/bag`)
  }
}
