<app-loader *ngIf="loading"></app-loader>
<div class="full-body">
    <!---->
    <nav class="navbar navbar-expand-lg navbar-light bg-light">
        <div class="container-fluid">
            <div class="d-flex">
                <button type="button" id="sidebarCollapse" class="btn text-sidebar bg-turbo-yellow menu-toggle-btn">
                    ☰
                </button>
                <div>
                    <h6>Donor Management</h6>
                    <p>Home <i class="fa fa-angle-right ml-2 mr-2"></i> Donor Management</p>
                </div>
            </div>
            <button class="btn btn-dark d-inline-block d-lg-none ml-auto" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <i class="fas fa-align-justify"></i>
            </button>

            <!-- <div class="collapse navbar-collapse" id="navbarSupportedContent">
                <ul class="nav navbar-nav ml-auto">
                    <li class="nav-item active">
                        <a class="nav-link" href="#">Page</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" href="#">Page</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" href="#">Page</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" href="#">Page</a>
                    </li>
                </ul>
            </div> -->
        </div>
    </nav>
    <!---->
    <div class="top-action">
        <div class="flex-1">
            <button class="btn add-donor-btn" (click)="addDonor(addDonorModal)"><i class="fa fa-plus"></i> Add Donor</button>
        </div>
        <div class="flex-2">
            <div class="donor-info-card">
                <h1 class="text-right">{{donors.length}}</h1>
                <p class="text-right">Total Donors</p>
                <div class="d-flex" style="justify-content: flex-end;">
                    <button (click)="exportAsXLSX()" class="btn mr-2" style="color: white; background-color: #b70303;" [disabled]="fetchingDonorsContacts">
                        <div class="spinner-border text-light" role="status" *ngIf="fetchingDonorsContacts">
                          <span class="sr-only">Loading...</span>
                        </div>
                        <i class="fa fa-download"></i>
                        Download Donors Contact 
                    </button>
                    <button class="btn view-btn text-right" [routerLink]="['/main/donors-breakdown']"><i class="fa fa-bar-chart"></i> View Donor Breakdown</button>    
                </div>
            </div>
        </div>
    </div>
    <hr>
    <!---->
    <div class="search-row">
        <div class="flex-1" style="display: flex; justify-content: space-between;">
            <h6>All Donors</h6>
            <div class="d-flex">
                <h3 class="cursor-pointer" (click)="isTableView = true"><i class="fa fa-list mr-4"></i></h3>
                <h3 class="cursor-pointer" (click)="isTableView = false"><i class="fa fa-th mr-4"></i></h3>
            </div>
        </div>
        <!---->
        <div class="flex-2">
            <div class="form-row">
                <div class="col-sm-6 form-group">
                    <ng-select
                        [items]="filterParams"
                        bindValue="value"
                        bindLabel="name"
                        class="formControl"
                        placeholder="filter"
                        (change)="filterDonorsByDropdown($event)"
                    ></ng-select>
                </div>
                <div class="col-sm-6 form-group">
                    <input type="search" class="form-control" 
                        [(ngModel)]="searchKey" (input)="filterDonors()" 
                        placeholder="filter donors by name"
                    >
                </div>
            </div>
        </div>
    </div>
    <!---->
    <div class="content table-responsive table-full-width" *ngIf="isTableView">
        <table class="table table-hover table-striped">
                <thead>
                    <tr>
                        <th>Donor ID</th>
                        <th>Name</th>
                        <th>Phone</th>
                        <th>Email</th>
                        <th>Blood Group</th>
                        <th>Gender</th>
                        <th>Region</th>
                        <th>Chiefdom</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let donor of filteredDonors | paginate: { itemsPerPage: 10, currentPage: p }">
                        <td>{{donor.id}}</td>
                        <td>{{donor.first_name | titlecase}} {{donor.last_name | titlecase}}</td>
                        <td>{{donor.phone}}</td>
                        <td>{{donor.email}}</td>
                        <td>{{donor.blood_type}}</td>
                        <td>{{donor.gender | titlecase}}</td>
                        <td>{{donor.state | titlecase}}</td>
                        <td>{{donor.city | titlecase}}</td>
                        <td>
                            <button class="btn btn-start" (click)="startDonation(startDonationModal,donor.id)">
                                Donate
                            </button>
                        </td>
                    </tr>
                </tbody>
        </table>
        <div style="display: flex; justify-content: flex-end;">
            <div>
                <pagination-controls (pageChange)="p = $event"></pagination-controls>                            
            </div>
        </div>
    </div>
    <!---->
    <div *ngIf="!isTableView">
        <div>
            <div class="row">
                <div class="col-sm-4" *ngFor="let donor of filteredDonors | paginate: { itemsPerPage: 10, currentPage: p }">
                    <div class="donor-grid-card">
                        <div class="circle">{{donor.blood_type}}</div>
                        <div class="details">
                            <p style="line-height: 15px;"><b>{{donor.first_name | titlecase}} {{donor.last_name | titlecase}}</b></p>
                            <p>{{donor.gender | titlecase}}</p>
                            <p>{{donor.phone}}</p>
                            <button class="btn btn-generate" (click)="startDonation(startDonationModal,donor.id)"><i class="fa fa-medkit mr-2"></i> Donate</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div style="display: flex; justify-content: flex-end;">
            <div>
                <pagination-controls (pageChange)="p = $event"></pagination-controls>                            
            </div>
        </div>
    </div>
</div>


<ng-template #addDonorModal>
    <div class="add-donor-modal">
        <h6>Add New Donor</h6>
        <div *ngIf="invalidDetails">
            <small class="text-center text-danger">All fields are required</small>
        </div>
        <form [formGroup]="addDonorform">
            <div class="form-row">
                <div class="col-sm-6">
                    <div class="form-group">
                        <label class="form-label">Firstname</label>
                        <input class="form-control" formControlName="first_name">
                    </div>
                </div>
                <div class="col-sm-6">
                    <div class="form-group">
                        <label class="form-label">Lastname</label>
                        <input class="form-control" formControlName="last_name">
                    </div>
                </div>
            </div>
            <div class="form-row">
                <div class="col-sm-6">
                    <div class="form-group">
                        <label class="form-label">Phone</label>
                        <input class="form-control" formControlName="phone">
                    </div>
                </div>
                <div class="col-sm-6">
                    <div class="form-group">
                        <label class="form-label">Email</label>
                        <input class="form-control" type="email" formControlName="email">
                    </div>
                </div>
            </div>
            <div class="form-row">
                <div class="col-sm-6">
                    <div class="form-group">
                        <label class="form-label">Gender</label>
                        <ng-select     
                            [items]="gender"
                            bindValue="name"
                            bindLabel="name"
                            formControlName="gender"
                        >
                        </ng-select>
                    </div>
                </div>
                <div class="col-sm-6">
                    <div class="form-group">
                        <label class="form-label">Blood Group</label>
                        <ng-select  
                            [items]="bloodTypes"
                            bindValue="name"
                            bindLabel="name"
                            formControlName="blood_type"
                        >
                        </ng-select>
                    </div>
                </div>
            </div>
            <div class="form-row">
                <div class="col-sm-6">
                    <div class="form-group">
                        <label class="form-label">Region</label>
                        <input class="form-control" type="text" formControlName="state">
                    </div>
                </div>
                <div class="col-sm-6">
                    <div class="form-group">
                        <label class="form-label">Chiefdom</label>
                        <input class="form-control" type="text" formControlName="city">
                    </div>
                </div>
            </div>
            <div class="center-x mb-2" *ngIf="saving">
                <app-small-loader></app-small-loader>
            </div>
            <div class="center-x">
                <button class="btn btn-add" (click)="save()">Submit</button>
            </div>
        </form>
    </div>
</ng-template>

<ng-template #startDonationModal>
    <div class="add-donor-modal">
        <h6>Enter Donor's Details</h6>
        <div *ngIf="donationInvalidDetails">
            <small class="text-center text-danger">All fields are required</small>
        </div>
        <form [formGroup]="donationForm">
            <div class="form-row">
                <div class="col-sm-6">
                    <div class="form-group">
                        <label class="form-label">Weight</label>
                        <input class="form-control" formControlName="weight">
                    </div>
                </div>
                <div class="col-sm-6">
                    <div class="form-group">
                        <label class="form-label">Blood Pressure</label>
                        <input class="form-control" formControlName="blood_pressure">
                    </div>
                </div>
            </div>
            <div class="form-check">
                <input type="checkbox" class="form-check-input" id="exampleCheck1" 
                    [(ngModel)]="useAutoGeneratedBagID" (change)="toggleAutoGenerateBagID()"
                    [ngModelOptions]="{standalone: true}"
                >
                <label class="form-check-label" for="exampleCheck1">Auto Generate BagID</label>
            </div><br>
            <div class="form-row">
                <div class="col-sm-6">
                    <div class="form-group">
                        <label class="form-label">Haemoglobin Level</label>
                        <input class="form-control" formControlName="haemoglobin">
                    </div>
                </div>
                <div class="col-sm-6">
                    <div class="form-group">
                        <label class="form-label">BagID
                            <div class="spinner-border spinner-border-sm" role="status" *ngIf="generatingBagID">
                                <span class="sr-only">Loading...</span>
                            </div>
                        </label>
                        <input class="form-control" formControlName="bag_id" *ngIf="!useAutoGeneratedBagID">
                        <input class="form-control" [(ngModel)]="generatedBagID" [ngModelOptions]="{standalone: true}" 
                            disabled *ngIf="useAutoGeneratedBagID"
                        >
                    </div>
                </div>
            </div>
        </form>
        <div class="center-x mb-2" *ngIf="savingDonation">
            <app-small-loader></app-small-loader>
        </div>
        <div style="display: flex; justify-content: center;">
            <button class="btn btn-generate" (click)="saveDonation()">Send</button>
        </div>
    </div>
</ng-template>