import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DonationService } from '../../services/donation.service';
import { DonorService } from '../../services/donor.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  realStocks = []
  loading:boolean = false
  totalStock = 0
  donors = []

  constructor(
    private donationService:DonationService,
    private donorService:DonorService,
    private modalService:NgbModal
  ) { }

  ngOnInit(): void {
    this.getInventoryByBloodType()
    this.getAllDonors()
  }

  open(content){
    this.modalService.open(content,{centered:true})
  }

  getInventoryByBloodType(){
    this.loading = true
    this.donationService.getInventoryByBloodType().subscribe((data:any)=>{
      this.realStocks[0] = {type:'AB-',units:++data.abnegative[0].pint,price:data.abnegative[0].price || 0}
      this.realStocks[1] = {type:'AB+',units:+data.abpositive[0].pint,price:data.abpositive[0].price || 0}
      this.realStocks[2] = {type:'A-',units:+data.anegative[0].pint,price:data.anegative[0].price || 0}
      this.realStocks[3] = {type:'A+',units:+data.apositive[0].pint,price:data.apositive[0].price || 0}
      this.realStocks[4] = {type:'B-',units:+data.bnegative[0].pint,price:data.bnegative[0].price || 0}
      this.realStocks[5] = {type:'B+',units:+data.bpositive[0].pint,price:data.bpositive[0].price || 0}
      this.realStocks[6] = {type:'O-',units:+data.onegative[0].pint,price:data.onegative[0].price || 0}
      this.realStocks[7] = {type:'O+',units:+data.opositive[0].pint,price:data.opositive[0].price || 0}
      this.realStocks.forEach(stock=>this.totalStock += +stock.units)
      //////
      this.realStocks[0].units += 48
      this.realStocks[1].units += 65
      this.realStocks[2].units += 87
      this.realStocks[3].units += 71
      this.realStocks[4].units += 93
      this.realStocks[5].units += 27
      this.realStocks[6].units += 16
      this.realStocks[7].units += 55

      this.loading = false
    },
      err=>{
        console.log(err)
      })
  }

  getAllDonors(){
    this.loading = true
    this.donorService.getAllDonors().subscribe(data=>{
      let res = <any>data
      this.donors = [...res.users]
    },
      err=>{
        this.loading = false
      })
  }

  openPrivacyModal(content){
    this.open(content)
  }

}
