import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';
import { DonorService } from '../../services/donor.service';
import { BankService } from '../../services/bank.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DonationService } from 'src/app/services/donation.service';

@Component({
  selector: 'app-appointments',
  templateUrl: './appointments.component.html',
  styleUrls: ['./appointments.component.scss']
})
export class AppointmentsComponent implements OnInit {

  isMissed:boolean = false
  isPending:boolean = true
  isAll:boolean = false

  isTodayPending:boolean = true
  isTodayCompleted:boolean = false

  currentPage = 1
  pMissed = 1
  pTodayPending = 1
  pTodayCompleted = 1
  pAllPending = 1

  allMissedAppointments = []
  filteredMissedAppointments = []
  missedFilterDate = null
  missedFilterKey = ''

  pendingAppointments = []
  filteredPendingAppointments = []
  pendingFilterDate = null
  pendingFilterKey = ''

  confirmedAppointments = []
  filteredConfirmedAppointments = []
  confirmedFilterDate = null
  confirmedFilterKey = ''

  allBanks = []
  confirming:boolean = false
  medicalReportForm:FormGroup
  updatingMedcialRecord:boolean = false
  

  todaysDate = ''
  searchTodayKey = ''
  filteredTodayPendingAppointments = []
  filteredTodayConfirmedAppointments = []

  searchAllPendingFilterKey = ''
  allPendingFilterDate = null

  donationInvalidDetails:boolean = false
  donationForm:FormGroup
  savingDonation:boolean = false
  selectedDonorID 




  constructor(
    private donorService:DonorService,
    private bankService:BankService,
    private modalService:NgbModal,
    private fb:FormBuilder,
    private donationService:DonationService
  ) { 
    this.setDonationFormValues()
    this.medicalReportForm = this.fb.group({
      donation_id:[],
      appointment_id:[],
      weight:[],
      height:[],
      body_mass_index:[],
      blood_pressure:[],
      blood_sugar:[],
      blood_group:[],
      genotype:[],
      date:[]
    })

  }

  ngOnInit(): void {
    let d = new Date()
    let year = d.getFullYear()
    let month = d.getMonth()+1
    let day = d.getDate()
    let today = `${year}-${month<10?`0${month}`:month}-${day<10?`0${day}`:day}`
    // let today = `2021-05-09`
    this.todaysDate = today
    this.getAllMissedAppointments()
    this.getPendingAppointmentsByDate(today)
    this.getConfirmedAppointmentsByDate(today)
    this.getAllPendingAppointments()
  }

  setDonationFormValues(){
    this.donationForm = this.fb.group({
      donor_id:[''],
      weight:['',Validators.required],
      blood_pressure:['',Validators.required],
      haemoglobin:['',Validators.required],
      bag_id:['',Validators.required]
    })
  }


  open(content){
    this.modalService.open(content,{centered:true})
  }

  dismissModal(){
    this.modalService.dismissAll()
  }

  openModal(content){
    this.medicalReportForm.reset()
    this.modalService.open(content)
  }

  toggleAppointments(type){
    if(type == 'missed'){
      this.isMissed = true
      this.isAll = false
      this.isPending = false
    }
    else if(type == 'pending'){
      this.isPending = true
      this.isAll = false
      this.isMissed = false
    }
    else{
      this.isAll = true
      this.isMissed = false
      this.isPending = false
    }
    
  }

  toggleIsToday(type){
    this.searchTodayKey = ''
    this.filteredTodayConfirmedAppointments = [...this.confirmedAppointments]
    this.filteredTodayPendingAppointments = [...this.pendingAppointments]
    if(type == 'pending'){
      this.isTodayPending = true
      this.isTodayCompleted = false
    }
    else{
      this.isTodayCompleted = true
      this.isTodayPending = false
    }
  }

  getAllMissedAppointments(){
    this.donorService.superAdminGetAllMissedAppointments().subscribe((data:any)=>{
      this.allMissedAppointments = [...data.missed]
      this.filteredMissedAppointments = [...this.allMissedAppointments]
      if(typeof this.allMissedAppointments == 'string'){
        this.allMissedAppointments = []
      }
      this.allMissedAppointments.forEach(app=>{
        app.name = `${app.first_name} ${app.last_name}`
      })
    },
      err=>{
        console.log(err)
      })
  }

  getMissedAppointmentsByDate(date){
    this.donorService.superAdminGetMissedApppintmentsByDate(date).subscribe(data=>{
      if(typeof data == 'string'){
        this.filteredMissedAppointments = []
      }
      else{
        let res = <any>data
        this.filteredMissedAppointments = [...res.individual]
        if(res.Group){
          this.filteredMissedAppointments = [...res.individual,...res.Group]
        }
      }
      // this.filteredMissedAppointments = [...this.allMissedAppointments]
      this.filteredMissedAppointments.forEach(app=>{
        app.name = `${app.first_name} ${app.last_name}`
      })
    },
      err=>{
      })
  }

  filterMissedAppointmentByDate(){
    if(!this.missedFilterDate) {
      return
    }
    this.getMissedAppointmentsByDate(this.missedFilterDate)
  }

  filterMissedAppointmentBySearchKey(){
    if(!this.missedFilterKey) {
      this.filteredMissedAppointments = [...this.allMissedAppointments]
      return
    }
    this.filteredMissedAppointments = this.allMissedAppointments.filter(x=>x.name.toLowerCase().includes(this.missedFilterKey.toLowerCase()))
  }

  getConfirmedAppointmentsByDate(date){
    this.donorService.superAdminGetConfirmedAppointmentsByDate(date).subscribe(data=>{
      if(typeof data == 'string'){
        this.confirmedAppointments = []
      }
      else{
        let res = <any>data
        this.confirmedAppointments = res
      }
      this.filteredTodayConfirmedAppointments = [...this.confirmedAppointments]
      this.confirmedAppointments.forEach(app=>{
        app.name = `${app.first_name} ${app.last_name}`
      })
    },
      err=>{
        // console.log(err)
      })
  }

  getPendingAppointmentsByDate(date){
    this.donorService.superAdminGetPendingApppintmentsByDate(date).subscribe(data=>{
      if(typeof data == 'string'){
        this.pendingAppointments = []
      }
      else{
        let res = <any>data
        this.pendingAppointments = [...res.individual,...res.Group]
      }
      this.filteredTodayPendingAppointments = [...this.pendingAppointments]
      this.pendingAppointments.forEach(app=>{
        app.name = `${app.first_name} ${app.last_name}`
      })
    },
      err=>{

      })
  }


  filterTodaysAppointment(){
    if(this.isTodayPending){
      this.filteredTodayPendingAppointments = this.pendingAppointments.filter(x=>x.name.toLowerCase().includes(this.searchTodayKey.toLowerCase()))
    }
    else if(this.isTodayCompleted){
      this.filteredTodayConfirmedAppointments = this.confirmedAppointments.filter(x=>x.name.toLowerCase().includes(this.searchTodayKey.toLowerCase()))
    }
  }

  allPendingAppointments = []
  filteredAllPendingAppointments = []

  getAllPendingAppointments(){
    this.donorService.getAllPendingAppointments().subscribe((data:any)=>{
      if(typeof data == 'string'){
        this.allPendingAppointments = []
        return
      }
      this.allPendingAppointments = [...data.individual, ...data.Group]
      this.filteredAllPendingAppointments = [...this.allPendingAppointments]
      this.allPendingAppointments.forEach(app=>{
        app.name = `${app.first_name} ${app.last_name}`
      })
    },
      err=>{
      })
  }

  filterAllPendingBySearchKey(){
    this.filteredAllPendingAppointments = this.allPendingAppointments.filter(x=>x.name.toLowerCase().includes(this.searchAllPendingFilterKey.toLowerCase()))
  }

  filterAllPendingByDate(){
    this.donorService.superAdminGetPendingApppintmentsByDate(this.allPendingFilterDate).subscribe((data:any)=>{
      if(typeof data == 'string'){
        this.filteredAllPendingAppointments = []
        return
      }
      // console.log(data)
      this.filteredAllPendingAppointments = [...data.individual, ...data.Group]
      this.filteredAllPendingAppointments.forEach(app=>{
        app.name = `${app.first_name} ${app.last_name}`
      })
    },
      err=>{

      })
  }

  refreshAllPending(){
    this.filteredAllPendingAppointments = [...this.allPendingAppointments]
    this.allPendingFilterDate = null
  }

  refreshAllMissed(){
    this.filteredMissedAppointments = [...this.allMissedAppointments]
  }

  confirmAppointment(app){
    this.confirming = true
    let obj = {
       appointment_id:app.id,
       bank_id:app.bank_id,
       date:app.date,
       time:app.time,
       amount_of_blood:1,
       status:'confirmed'
    }
    this.donorService.confirmAppointment(app.user_id,obj).subscribe(data=>{
      this.getPendingAppointmentsByDate(this.todaysDate)
      // console.log(data)
      this.modalService.dismissAll()
      this.ngOnInit()
      this.confirming = false
      Swal.fire(
        'Success',
        `Appointment has been confirmed`,
        'success'
      )
    },
      err=>{
        // console.log(err)
        this.modalService.dismissAll()
        this.confirming = false
        Swal.fire(
          'Oops!',
          `Something went wrong`,
          'error'
      )
    })
  }

  startDonation(modal, donorID){
    this.open(modal)
    this.selectedDonorID = donorID
  }


  saveDonation(){
    this.donationInvalidDetails = false
    this.donationForm.value.donor_id = this.selectedDonorID
    if(this.donationForm.invalid){
      this.donationInvalidDetails = true
      return
    }
    else{
      this.savingDonation = true
      this.donationService.makeDonation(this.donationForm.value).subscribe(data=>{
        this.modalService.dismissAll()
        Swal.fire(         
          'Success',
          `Donation has been saved`,
          'success'
        )
      },
        err=>{
          this.modalService.dismissAll()
          Swal.fire(         
            'Oops',
            `Something went wrong`,
            'error'
          )
        })
    }
  }


  getAllBanks(){
    this.bankService.getConfirmedBanks().subscribe(data=>{
      let res = <any>data
      this.allBanks = res.blood_banks
      // console.log(this.allBanks)
    },
    err=>{
    })
  }

  getBankName(id){
    try{
      return this.allBanks.find(x=>x.id == id).name
    }
    catch{
      return 'NIL'
    }
  }  

  updateMedicalRecord(app){
    this.updatingMedcialRecord = true
    this.medicalReportForm.value.appointment_id = app.appointment_id
    this.medicalReportForm.value.donation_id = app.id
    this.medicalReportForm.value.date = app.date
    this.donorService.updateMedicalReport(app.user_id,this.medicalReportForm.value).subscribe(data=>{
      this.updatingMedcialRecord = false
      // console.log(data)
      this.modalService.dismissAll()
      Swal.fire(
        'Success',
        `Medical report has been updated for ${app.name}`,
        'success'
      )
    },
      err=>{
        Swal.fire(
          'Error',
          `Something went wrong`,
          'success'
        )
        this.updatingMedcialRecord = false
        this.modalService.dismissAll()
      })
  }


  


}
