import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class GeneralService {

  donorCareApiUrl = environment.donorCareApiurl
  smartCenterApiUrl = environment.smartCenterApiUrl

  constructor() { }
}
