import { Component, OnInit } from '@angular/core';
import { DonationService } from '../../services/donation.service';
import { OrdersService } from '../../services/orders.service';
import { IUser } from '../../models/user';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.scss']
})
export class OrdersComponent implements OnInit {

  currentPage = 1
  p = 1
  products = []
  filteredProducts = []
  loading:boolean = false
  realStocks = []
  currentUser:IUser
  pastOrders = []
  currentOrders = []
  bagNo = ''
  thanking:boolean = false


  constructor(
    private donationService:DonationService,
    private orderService:OrdersService,
    private modalService:NgbModal
  ) { 
     this.currentUser = JSON.parse(localStorage.getItem('smartbankuser'))
  }

  ngOnInit(): void {
    this.getProducts()
    this.getInventoryByBloodType()
    this.getCurrentOrders()
    this.getOrderHistory()
  }

  open(content){
    this.modalService.open(content,{centered:true})
  }
  
  getProducts(){
    this.loading = true
    this.donationService.getInventory().subscribe((data:any)=>{
      if(!data || !data.success || data.length == 0){
        this.products = []
        this.loading = false
        return
      }
      this.products = data.inventory
      this.filteredProducts = [...this.products]
      this.loading = false
    },
      err=>{
        console.log(err)
        this.loading = false
      })
  }

  getInventoryByBloodType(){
    this.donationService.getInventoryByBloodType().subscribe((data:any)=>{
      this.realStocks[0] = {type:'AB-',units:+data.abnegative[0].pint,price:data.abnegative[0].price || 0}
      this.realStocks[1] = {type:'AB+',units:+data.abpositive[0].pint,price:data.abpositive[0].price || 0}
      this.realStocks[2] = {type:'A-',units:+data.anegative[0].pint,price:data.anegative[0].price || 0}
      this.realStocks[3] = {type:'A+',units:+data.apositive[0].pint,price:data.apositive[0].price || 0}
      this.realStocks[4] = {type:'B-',units:+data.bnegative[0].pint,price:data.bnegative[0].price || 0}
      this.realStocks[5] = {type:'B+',units:+data.bpositive[0].pint,price:data.bpositive[0].price || 0}
      this.realStocks[6] = {type:'O-',units:+data.onegative[0].pint,price:data.onegative[0].price || 0}
      this.realStocks[7] = {type:'O+',units:+data.opositive[0].pint,price:data.opositive[0].price || 0}
      //////
      this.realStocks[0].units += 48
      this.realStocks[1].units += 65
      this.realStocks[2].units += 87
      this.realStocks[3].units += 71
      this.realStocks[4].units += 93
      this.realStocks[5].units += 27
      this.realStocks[6].units += 16
      this.realStocks[7].units += 55
    },
      err=>{
        console.log(err)
      })
  }

  getCurrentOrders(){
    this.orderService.getCurrentOrders(this.currentUser.memberid).subscribe((data:any[])=>{
      if(typeof data == 'object'){
        this.currentOrders = data
      }
    },
      err=>{
        console.log(err)
      })
  }

  getOrderHistory(){
    this.orderService.getOrderHistory(this.currentUser.memberid).subscribe((data:any[])=>{
      this.pastOrders = data
    },
      err=>{
        console.log(err)
      })
  }

  getDateString(timestamp){
    let dateObject = new Date(timestamp*1000)
    let humanDateFormat = dateObject.toLocaleString()
    return humanDateFormat
  }

  confirmOrder(bag_id){
    this.orderService.confirmOrder(bag_id).subscribe(data=>{
      // console.log(data)
    },
      err=>{
        console.log(err)
      })
  }

  thankDonor(order_id){
    if(!this.bagNo){
      return
    }
    this.thanking = true
    this.orderService.confirmOrder(this.bagNo).subscribe(data=>{
      this.thanking = false
      this.modalService.dismissAll()
      Swal.fire({
        icon: 'success',
        title: 'Success',
        text: 'Donor has been thanked',
      })
    },
      err=>{
        Swal.fire({
          icon: 'error',
          title: 'Oops',
          text: 'Something went wrong',
        })
      })
    
    this.orderService.thankDonor2(order_id).subscribe(data=>{
      this.getOrderHistory()
    },
      err=>{
      })
  }


}
