import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DonationsComponent } from './components/donations/donations.component';
import { DonorsComponent } from './components/donors/donors.component';
import { LoginComponent } from './components/login/login.component';
import { MainComponent } from './components/main/main.component';
import { ScreeningComponent } from './components/screening/screening.component';
import { InvetoryComponent } from './components/invetory/invetory.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { DonorBreakdownComponent } from './components/donor-breakdown/donor-breakdown.component';
import { AppointmentsComponent } from './components/appointments/appointments.component';
import { OrdersComponent } from './components/orders/orders.component';
import { ExpiredComponent } from './components/expired/expired.component';
import { UploadComponent } from './components/upload/upload.component';
import { AuthGuard } from './auth.guard';

const routes: Routes = [
  {
    path:'',
    component:LoginComponent
  },
  {
    path:'main',
    component:MainComponent,
    canActivate:[AuthGuard],
    children:[
      {
        path:'donors',
        component:DonorsComponent
      },
      {
        path:'donations',
        component:DonationsComponent
      },
      {
        path:'screening',
        component:ScreeningComponent
      },
      {
        path:'inventory',
        component:InvetoryComponent
      },
      {
        path:'dashboard',
        component:DashboardComponent
      },
      {
        path:'donors-breakdown',
        component:DonorBreakdownComponent
      },
      {
        path:'appointments',
        component:AppointmentsComponent
      },
      {
        path:'orders',
        component:OrdersComponent
      },
      {
        path:'expired',
        component:ExpiredComponent
      },
      {
        path:'upload',
        component:UploadComponent
      }
    ]
  },
  {
    path:'**',
    component:LoginComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
