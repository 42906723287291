<app-loader *ngIf="loading"></app-loader>
<div class="full-body" *ngIf="!loading">
    <div class="top-header">
        <div class="top-header-flex">
            <div class="d-flex" style="align-items: center;">
                <button type="button" id="sidebarCollapse" class="btn text-sidebar bg-turbo-yellow menu-toggle-btn">
                    ☰
                    <span></span>
                </button>
                <h6 id="sidebarCollapse">Hello User</h6>
            </div>
            <button class="btn btn-help"><i class="fa fa-question-circle"></i> Help and support</button>
        </div>
    </div>
    <div class="stats">
        <div class="row">
            <div class="col-sm-4">
                <div class="inner-card">
                    <h1 *ngIf="stats.donations_count<1">0</h1>
                    <h1 *ngIf="stats.donations_count>0">{{+stats.donations_count + 4328}}</h1>
                    <p class="total"><b>Donations</b></p>
                    <p class="text-muted">Total No. of donations</p>
                </div>
            </div>
            <div class="col-sm-4">
                <div class="inner-card">
                    <h1>{{stats.untested_count}}</h1>
                    <p class="total"><b>Untested</b></p>
                    <p class="text-muted">Total No. of untested donations</p>
                </div>
            </div>
            <div class="col-sm-4">
                <div class="inner-card last-card">
                    <h1 *ngIf="stats.donations_count<1">0</h1>
                    <h1 *ngIf="stats.donations_count>0">{{+stats.donations_count + 4328}}</h1>
                    <p class="total"><b>Donations</b></p>
                    <p class="text-muted">Total No. of donations</p>
                </div>
            </div>
        </div>
    </div>
    <!---->
    <div class="form-row">
        <div class="col-sm-4">
            <div class="form-group">
                <label class="form-label">Filter by date</label>
                <input class="form-control" type="date" (change)="filterDonationsByDate()" [(ngModel)]="filterDate">
            </div>
        </div>
        <div class="col-sm-4">
            <div class="form-group">
                <label class="form-label">Search</label>
                <input class="form-control" type="search" placeholder="filter by bagID" [(ngModel)]="searchKey" (input)="filterBySearchKey()">
            </div>
        </div>
        <div class="col-sm-4">
            <button class="btn btn-success" style="margin-top: 32px;" (click)="reset()"><i class="fa fa-refresh"></i> Reset</button>
        </div>
    </div>
    <div class="content table-responsive table-full-width">
        <table class="table table-hover table-striped">
            <thead>
                <tr>
                    <th>Donation ID</th>
                    <th>Bag ID</th>
                    <th>Date</th>
                    <th>Time</th>
                    <th>Donation Status</th>
                    <th></th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let donation of filteredDonations | paginate: { itemsPerPage: 10, currentPage: p }; index as i">
                    <td>{{donation.donation_id}}</td>
                    <td>{{donation.bag_id}}</td>
                    <td>{{donation.created_at.split(' ')[0]}}</td>
                    <td>{{donation.created_at.split(' ')[1]}}</td>
                    <td>{{donation.status}}</td>
                    <td>
                        <div class="dropdown">
                            <button class="btn btn-actions dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" [disabled]="donation.status == 'testing'">
                                <i class="fa fa-cog"></i>
                            </button>
                            <div class="dropdown-menu cursor-pointer" aria-labelledby="dropdownMenuButton" (click)="moveToTest(i, donation.donation_id, donation.bag_id)">
                                <a class="dropdown-item">Move to test</a>
                            </div>
                        </div>
                    </td>
                    <td><app-small-loader *ngIf="saving[i]"></app-small-loader></td>
                </tr>
            </tbody>
        </table>
        <div *ngIf="filteredDonations.length<1">
            <h1 class="text-muted text-center">There are no donations</h1>
        </div>
        <div style="display: flex; justify-content: flex-end;" class="pagination-control">
            <div>
                <pagination-controls (pageChange)="p = $event"></pagination-controls>                            
            </div>
        </div>
    </div>
</div>