import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

declare var $:any

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit {

  currentUrl = ''

  constructor(
    private router:Router,
    private modalService:NgbModal
  ) { 
    this.router.events.subscribe( (event:any) => {
      let url = event.url
      this.currentUrl = url
    })
  }

  ngOnInit(): void {
    // this.closeNav()
  }

  open(content){
    this.modalService.open(content,{centered:true})
  }

  openNav() {
    const closeBtn:any = document.querySelector('.closebtn')
    const openBtn:any = document.querySelector('.open-btn')
    openBtn.style.display = 'none'
    closeBtn.style.display = 'block'
    document.getElementById("mySidebar").style.width = "250px";
    document.getElementById("main").style.marginLeft = "250px";
    let menus = document.querySelectorAll('.menu-name')
    menus.forEach((menu:any)=>menu.style.opacity = '1')
  }

  closeNav() {
    const closeBtn:any = document.querySelector('.closebtn')
    const openBtn:any = document.querySelector('.open-btn')
    openBtn.style.display = 'block'
    closeBtn.style.display = 'none'
    document.getElementById("mySidebar").style.width = "50px";
    document.getElementById("main").style.marginLeft= "50px";
    let menus = document.querySelectorAll('.menu-name')
    menus.forEach((menu:any)=>menu.style.opacity = '0')
  }

  toggleMenu(id) {
    // console.log(id);
    let nav = document.getElementById("main-nav");
    if (id === 1) {
      nav.style.left = "0%";
    } else {
      nav.style.left = "-100%";
    }
  }  

  logout(){
    this.router.navigateByUrl('/')
    localStorage.removeItem('smartbankuser')
  }

  openPrivacyModal(content){
    this.open(content)
  }

}


$(document).ready(function () {
  $('#sidebarCollapse').on('click', function () {
        $('#sidebar').toggleClass('active');
    });
});