import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GeneralService } from './general.service'


@Injectable({
  providedIn: 'root'
})
export class BankService {

  constructor(
    private httpClient:HttpClient,
    private generalService:GeneralService
  ) { }

  getConfirmedBanks(){
    return this.httpClient.get(`${this.generalService.donorCareApiUrl}super_admin/banks`)
  }

}
