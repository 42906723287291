<div class="inventory-update-container">
    <h2>UPLOAD TEST RESULT</h2>
    <div class="input-container">
      <select [(ngModel)]="newItem.product" (change)="onProductChange()" placeholder="Select Product">
        <option *ngFor="let product of products" [value]="product">{{ product }}</option>
      </select>
      <select *ngIf="newItem.product" [(ngModel)]="newItem.result" placeholder="Select Result">
        <option *ngFor="let result of results" [value]="result">{{ result }}</option>
      </select>
      <select *ngIf="newItem.product" [(ngModel)]="newItem.blood_types" placeholder="Select Blood Group">
        <option *ngFor="let group of blood_types" [value]="group">{{ group }}</option>
      </select>
      <select *ngIf="newItem.product" [(ngModel)]="newItem.genotype" placeholder="Select Genotype">
        <option *ngFor="let type of genotypes" [value]="type">{{ type }}</option>
      </select>
      <button (click)="addItem()">Add</button>
    </div>
    <div class="form-row mb-4 mt-4">
        <div class="">
          <div class="form-check">
            <input class="form-check-input" type="radio" name="condition" value="good" [(ngModel)]="newItem.condition" (change)="checkBloodStatus()" checked>
            <label class="form-check-label">Mark as good blood</label>
          </div>
        </div>
        <div class="col-sm-2">
          <div class="form-check">
            <input class="form-check-input" type="radio" name="condition" value="bad" [(ngModel)]="newItem.condition" (change)="checkBloodStatus()">
            <label class="form-check-label">Mark as bad blood</label>
          </div>
        </div>
      <div class="" *ngIf="newItem.condition === 'bad'">
        <div *ngIf="invalidReason">
            <small class="text-danger text-center">Give a reason why the blood is bad (before you clicked on add)</small>
          </div>
        <textarea class="" [(ngModel)]="newItem.reason"></textarea>
      </div>
    </div>
    <table>
      <thead>
        <tr>
          <th>Product</th>
          <th>Result</th>
          <th>Blood Group</th>
          <th>Genotype</th>
          <th>Reason</th>
          <th>Remove</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of inventory; let i = index">
          <td>{{ item.product }}</td>
          <td>{{ item.result }}</td>
          <td>{{ item.blood_types }}</td>
          <td>{{ item.genotype }}</td>
          <td>{{ item.reason }}</td>
          <td><button (click)="removeItem(i)">Remove</button></td>
        </tr>
      </tbody>
    </table>
    <button (click)="updateInventory()">UPDATE</button>
  </div>
  
