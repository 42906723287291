<!-- <app-loader *ngIf="loading"></app-loader> -->
<div class="full-body">
    <!---->
    <nav class="navbar navbar-expand-lg navbar-light bg-light">
        <div class="container-fluid">
            <div class="d-flex">
                <button type="button" id="sidebarCollapse" class="btn text-sidebar bg-turbo-yellow menu-toggle-btn">
                    ☰
                </button>
                <div>
                    <h6>Appointments</h6>
                    <p>Home <i class="fa fa-angle-right"></i> Appointments</p>
                </div>
            </div>
            <button class="btn btn-dark d-inline-block d-lg-none ml-auto" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <i class="fas fa-align-justify"></i>
            </button>

            
        </div>
    </nav>

    <!---->
    
    <div class="top-cards">
        <div class="row">
            <div class="col-sm-4">
                <div class="total-card" [ngClass]="{'active-app-card':isAll}" (click)="toggleAppointments('all')">
                    <h1>{{pendingAppointments.length}}</h1>
                    <div>
                        <h6>Appointments for today</h6>
                        <p>{{todaysDate}}</p>
                    </div>
                </div>
            </div>
            <div class="col-sm-4">
                <div class="pending-card" [ngClass]="{'active-app-card':isPending}" (click)="toggleAppointments('pending')">
                    <div>
                        <h3>{{allPendingAppointments.length}}</h3>
                        <p>Total Pending Appointments</p>
                    </div>                    
                </div>
            </div>
            <div class="col-sm-4">
                <div class="missed-card" [ngClass]="{'active-app-card':isMissed}" (click)="toggleAppointments('missed')">
                    <div>
                        <h3>{{allMissedAppointments.length}}</h3>
                        <p>Total Missed Appointments</p>                
                    </div>
                </div>
            </div>
        </div>
    </div>

    <hr style="margin-top: 50px; margin-bottom: 50px;">

    <!--Missed Appointments-->
    <div *ngIf="isMissed">
        <div class="missed-pending-header">
            <h6>Missed Apppointments</h6>
            <div class="inputs">
                <input 
                    type="date" class="form-control mr-4" 
                    [(ngModel)]="missedFilterDate" (change)="filterMissedAppointmentByDate()">

                <input type="search" class="form-control" 
                    placeholder="Type to filter by donor"
                     [(ngModel)]="missedFilterKey" (input)="filterMissedAppointmentBySearchKey()">
                <button class="btn btn-reset ml-2" (click)="refreshAllMissed()"><i class="fa fa-refresh"></i></button>
            </div>
        </div>
        <!---->
        <div class="content table-responsive table-full-width">
            <table class="table table-hover table-striped">
                    <thead>
                        <tr>
                            <th>Donor</th>
                            <th>Time</th>
                            <th>Date</th>
                            <th>Phone</th>
                            <th>Blood Type</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let app of filteredMissedAppointments | paginate: { itemsPerPage: 10, currentPage: pMissed}">
                            <td>{{app.first_name}} {{app.last_name}}</td>
                            <td>{{app.time}}</td>
                            <td>{{app.date}}</td>
                            <td>{{app.phone}}</td>
                            <td>{{app.blood_type}}</td>
                            <td>
                                <button class="btn btn-reschedule" (click)="startDonation(startDonationModal,app.user_id)"> Donate</button>
                            </td>
                        </tr>
                    </tbody>
            </table>
        </div>
        <div style="display: flex; justify-content: flex-end;" class="pagination-control">
            <div>
                <pagination-controls (pageChange)="pMissed = $event"></pagination-controls>                            
            </div>
        </div>
    </div>

    <!--Pending Appointments-->
    <div *ngIf="isPending">
        <div class="missed-pending-header">
            <h6>Pending Apppointments</h6>
            <div class="inputs">
                <input type="date" class="form-control mr-4" [(ngModel)]="allPendingFilterDate" (change)="filterAllPendingByDate()">
                <input type="search" class="form-control" placeholder="Type to filter by donor" 
                    [(ngModel)]="searchAllPendingFilterKey"
                    (input)="filterAllPendingBySearchKey()"
                >
                <button class="btn btn-reset ml-2" (click)="refreshAllPending()"><i class="fa fa-refresh"></i></button>
            </div>
        </div>
        <!---->
        <div class="content table-responsive table-full-width">
            <table class="table table-hover table-striped">
                  <thead>
                      <tr>
                          <th>Donor</th>
                          <th>Time</th>
                          <th>Date</th>
                          <th>Phone</th>
                          <th>Blood Type</th>
                          <th class="text-center">Bank</th>
                          <th>Action</th>
                      </tr>
                  </thead>
                  <tbody>
                      <tr *ngFor="let app of filteredAllPendingAppointments | paginate: { itemsPerPage: 10, currentPage: pAllPending }">
                          <td>{{app.first_name}} {{app.last_name}}</td>
                          <td>{{app.time}}</td>
                          <td>{{app.date}}</td>
                          <td>{{app.phone}}</td>
                          <td>{{app.blood_type}}</td>
                          <td>{{getBankName(app.bank_id)}}</td>
                          <td>
                              <button class="btn confirm-app-btn" (click)="open(confirmModal)">Confirm</button>
                              <ng-template #confirmModal>
                                  <div>
                                      <div class="confirm-modal text-center">
                                          <h3><b>Confirm Appointment</b></h3>
                                          <h3><i class="fa fa-check checked-icon"></i></h3>
                                          <p>Are you sure you want to confirm Appointment for {{app.first_name}} {{app.last_name}} <b>({{app.date}})</b>?</p>
                                          <div style="display: flex; justify-content: center" *ngIf="confirming">
                                              <app-small-loader></app-small-loader>
                                          </div>
                                          <div>
                                              <button class="btn cancel-btn mr-2" (click)="dismissModal()"><i class="fa fa-times"></i> No</button>
                                              <button class="btn confirm-btn" (click)="confirmAppointment(app)"><i class="fa fa-check"></i> Yes</button>
                                          </div>
                                      </div>
                                  </div>
                              </ng-template>
                          </td>
                      </tr>
                  </tbody>
            </table>
        </div>
        <div *ngIf="filteredAllPendingAppointments.length<1">
            <h1 class="text-center text-muted">There are no pending appointments</h1>
        </div>
        <div style="display: flex; justify-content: flex-end;" class="pagination-control">
            <div>
                <pagination-controls (pageChange)="pAllPending = $event"></pagination-controls>                            
            </div>
        </div>
    </div>

    <!--All Appointments-->
    <div *ngIf="isAll">
        <h6>Today's Appointments</h6>
        <div class="todays-app-header">
            <div class="tab-headers">
                <p class="pending" [ngClass]="{'active-todays-tab':isTodayPending}" (click)="toggleIsToday('pending')">Pending</p>
                <p class="completed" [ngClass]="{'active-todays-tab':isTodayCompleted}" (click)="toggleIsToday('completed')">Completed</p>
            </div>
            <div>
                <input type="search" class="form-control" 
                    placeholder="Type to filter by donor"
                    [(ngModel)]="searchTodayKey"
                    (input)="filterTodaysAppointment()"    
                >
            </div>
        </div>
        <!---->
        <div *ngIf="isTodayPending">
            <div class="content table-responsive table-full-width">
                <table class="table table-hover table-striped">
                      <thead>
                          <tr>
                              <th>Donor</th>
                              <th>Time</th>
                              <th>Date</th>
                              <th>Phone</th>
                              <th>Blood Type</th>
                              <th class="text-center">Bank</th>
                              <th>Action</th>
                          </tr>
                      </thead>
                      <tbody>
                          <tr *ngFor="let app of filteredTodayPendingAppointments | paginate: { itemsPerPage: 10, currentPage: pTodayPending}">
                              <td>{{app.first_name}} {{app.last_name}}</td>
                              <td>{{app.time}}</td>
                              <td>{{app.date}}</td>
                              <td>{{app.phone}}</td>
                              <td>{{app.blood_type}}</td>
                              <td>{{getBankName(app.bank_id)}}</td>
                              <td>
                                  <button class="btn confirm-app-btn" (click)="open(confirmModal)">Confirm</button>
                                  <ng-template #confirmModal>
                                      <div>
                                          <div class="confirm-modal text-center">
                                              <h3><b>Confirm Appointment</b></h3>
                                              <h3><i class="fa fa-check checked-icon"></i></h3>
                                              <p>Are you sure you want to confirm Appointment for {{app.first_name}} {{app.last_name}} <b>({{app.date}})</b>?</p>
                                              <div style="display: flex; justify-content: center" *ngIf="confirming">
                                                  <app-small-loader></app-small-loader>
                                              </div>
                                              <div>
                                                  <button class="btn cancel-btn mr-2" (click)="dismissModal()"><i class="fa fa-times"></i> No</button>
                                                  <button class="btn confirm-btn" (click)="confirmAppointment(app)"><i class="fa fa-check"></i> Yes</button>
                                              </div>
                                          </div>
                                      </div>
                                  </ng-template>
                              </td>
                          </tr>
                      </tbody>
                </table>
                <div *ngIf="pendingAppointments.length<1">
                    <h1 class="text-muted text-center">There are no pending appointments</h1>
                </div>
            </div>
            <div style="display: flex; justify-content: flex-end;">
                <div>
                    <pagination-controls (pageChange)="pTodayPending = $event"></pagination-controls>                            
                </div>
            </div>
        </div>
        <div *ngIf="isTodayCompleted">
            <div class="content table-responsive table-full-width">
                <table class="table table-hover table-striped">
                      <thead>
                          <tr>
                              <th>Donor</th>
                              <th>Time</th>
                              <th>Date</th>
                              <th>Phone</th>
                              <th>Email</th>
                              <th class="text-center">Bank</th>
                              <th>Rating</th>
                              <th></th>
                          </tr>
                      </thead>
                      <tbody>
                          <tr *ngFor="let app of filteredTodayConfirmedAppointments | paginate: { itemsPerPage: 10, currentPage: pTodayCompleted}">
                              <td>{{app.first_name}} {{app.last_name}}</td>
                              <td>{{app.time}}</td>
                              <td>{{app.date}}</td>
                              <td>{{app.phone}}</td>
                              <td>{{app.email}}</td>
                              <td>{{getBankName(app.bank_id)}}</td>
                              <td *ngIf="app.rating!='0'">{{app.rating}}</td>
                              <td *ngIf="app.rating=='0'">NIL</td>
                              <td style="cursor: pointer">
                                  <div class="dropdown show dropleft">
                                  <span role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                      <i class="fa fa-cog"></i>
                                  </span>
                                  
                                  <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                      <span class="dropdown-item" (click)="openModal(medModal)"><i class="fa fa-medkit"></i> Medical Report</span>
                                  </div>
                                  </div>
                                  <ng-template #medModal>
                                      <div>
                                          <div class="med-modal">
                                              <h6><b><i class="fa fa-medkit"></i> Upload Medical Report ({{app.name}})</b></h6>
                                              <form [formGroup]="medicalReportForm">
                                                  <div class="form-row">
                                                          <div class="form-group col-md-6">
                                                              <label class="form-label">Weight</label>
                                                              <input type="text" class="form-control" formControlName="weight">
                                                          </div>
                                                          <div class="form-group col-md-6">
                                                              <label class="form-label">Height</label>
                                                              <input type="text" class="form-control" formControlName="height">
                                                          </div>
                                                      </div>
                                                      <div class="form-row">
                                                          <div class="form-group col-md-6">
                                                              <label class="form-label">Body Mass Index</label>
                                                              <input type="text" class="form-control" formControlName="body_mass_index">
                                                          </div>
                                                          <div class="form-group col-md-6">
                                                              <label class="form-label">Blood Pressure</label>
                                                              <input type="text" class="form-control" formControlName="blood_pressure">
                                                          </div>
                                                      </div>
                                                      <div class="form-row">
                                                          <div class="form-group col-md-6">
                                                              <label class="form-label">Blood Sugar</label>
                                                              <input type="text" class="form-control" formControlName="blood_sugar">
                                                          </div>
                                                          <div class="form-group col-md-6">
                                                              <label class="form-label">Blood Group</label>
                                                              <ng-select
                                                                  [items]="bloodGroups"
                                                                  bindLabel="name"
                                                                  bindValue="name"
                                                                  formControlName="blood_group"
                                                              >
                                                              </ng-select>
                                                          </div>
                                                      </div>
                                                      <div class="form-row">
                                                          <div class="form-group col-md-6">
                                                              <label class="form-label">Genotype</label>
                                                              <ng-select
                                                                  [items]="genotypes"
                                                                  bindLabel="name"
                                                                  bindValue="name"
                                                                  formControlName="genotype"
                                                              >
                                                              </ng-select>
                                                          </div>
                                                      </div>
                                                      <div class="text-right" style="display: flex; justify-content: flex-end">
                                                          <app-small-loader *ngIf="updatingMedcialRecord"></app-small-loader>
                                                          <button class="btn confirm-btn" (click)="updateMedicalRecord(app)"><i class="fa fa-upload"></i> Upload</button>
                                                      </div>
                                              </form>                                                                                    
                                          </div>
                                      </div>
                                  </ng-template>
                              </td>
                          </tr>
                      </tbody>
                </table>
                <div *ngIf="confirmedAppointments.length<1">
                    <h1 class="text-muted text-center">There are no completed appointments</h1>
                </div>

          </div>
          <div style="display: flex; justify-content: flex-end;">
            <div>
                <pagination-controls (pageChange)="pTodayCompleted = $event"></pagination-controls>                            
            </div>
        </div>
        </div>
    </div>
    
</div>


<ng-template #startDonationModal>
    <div class="add-donor-modal">
        <h6>Enter Donor's Details</h6>
        <div *ngIf="donationInvalidDetails">
            <small class="text-center text-danger">All fields are required</small>
        </div>
        <form [formGroup]="donationForm">
            <div class="form-row">
                <div class="col-sm-6">
                    <div class="form-group">
                        <label class="form-label">Weight</label>
                        <input class="form-control" formControlName="weight">
                    </div>
                </div>
                <div class="col-sm-6">
                    <div class="form-group">
                        <label class="form-label">Blood Pressure</label>
                        <input class="form-control" formControlName="blood_pressure">
                    </div>
                </div>
            </div>
            <div class="form-row">
                <div class="col-sm-6">
                    <div class="form-group">
                        <label class="form-label">Haemoglobin Level</label>
                        <input class="form-control" formControlName="haemoglobin">
                    </div>
                </div>
                <div class="col-sm-6">
                    <div class="form-group">
                        <label class="form-label">BagID</label>
                        <input class="form-control" formControlName="bag_id">
                    </div>
                </div>
            </div>
        </form>
        <div class="center-x mb-2" *ngIf="savingDonation">
            <app-small-loader></app-small-loader>
        </div>
        <div style="display: flex; justify-content: center;">
            <button class="btn btn-generate" (click)="saveDonation()">Send</button>
        </div>
    </div>
</ng-template>