import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  loginForm:FormGroup
  loading:boolean = false
  invalidCredentials:boolean = false

  constructor(
    private fb:FormBuilder,
    private userService:UserService,
    private router:Router
  ) { }

  ngOnInit(): void {
    this.setLoginForm()
  }

  setLoginForm(){
    this.loginForm = this.fb.group({
      username:['',Validators.required],
      password:['',Validators.required]
    })
  }

  login(){
    this.invalidCredentials = false
    if(this.loginForm.invalid) return
    this.loading = true
    this.userService.login(this.loginForm.value).subscribe((data:any)=>{
      this.loading = false
      if(data?.status == 'error'){
        this.invalidCredentials = true
        this.loading = false
        return
      }
      localStorage.setItem('smartbankuser',JSON.stringify(data))
      this.router.navigateByUrl('/main/appointments')
    },
      err=>{
        console.log(err)
        this.invalidCredentials = true
        this.loading = false
    })
  }

}
