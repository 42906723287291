import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GeneralService } from './general.service'

@Injectable({
  providedIn: 'root'
})
export class DonorService {

  constructor(
    private httpClient:HttpClient,
    private generalService:GeneralService
  ) { }

  signupUser(obj){
    return this.httpClient.post(`${this.generalService.donorCareApiUrl}authentication/register`,obj)
  }

  getAllDonors(){
    return this.httpClient.get(`${this.generalService.donorCareApiUrl}super_admin/users`)
  }

  getDonorsNumbersandEmails(){
    return this.httpClient.get(`${this.generalService.donorCareApiUrl}super_admin/donors/csv`)
  }

  superAdminGetAllMissedAppointments(){
    return this.httpClient.get(`${this.generalService.donorCareApiUrl}super_admin/missed/all`)
  }

  superAdminGetMissedApppintmentsByDate(date){
    return this.httpClient.post(`${this.generalService.donorCareApiUrl}super_admin/missed`,{date})
  }

  superAdminGetPendingApppintmentsByDate(date){
    return this.httpClient.post(`${this.generalService.donorCareApiUrl}super_admin/upcoming`,{date})
  }

  superAdminGetConfirmedAppointmentsByDate(date){
    return this.httpClient.post(`${this.generalService.donorCareApiUrl}super_admin/appointments/confirmed/option`,{date})
  }

  confirmAppointment(userID,obj){
    return this.httpClient.post(`${this.generalService.donorCareApiUrl}admin/report/donation/${userID}`,obj)
  }

  updateMedicalReport(userID,obj){
    return this.httpClient.post(`${this.generalService.donorCareApiUrl}admin/medical/${userID}`,obj)
  }

  getAllPendingAppointments(){
    return this.httpClient.get(`${this.generalService.donorCareApiUrl}super_admin/all`)
  }

}

//PENDING
// https://app.lifebank.ke/donorxapi/v1/super_admin/all

//CONFIRMED
// /appointments/confirmed

//MISSED
// https://app.lifebank.ke/donorxapi/v1/super_admin/missed
// {
//   "date":"2021-05-08"
// }