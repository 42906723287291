<app-loader *ngIf="loading"></app-loader>
<div class="full-body">
    <!---->
    <nav class="navbar navbar-expand-lg navbar-light bg-light">
        <div class="container-fluid">
            <div class="d-flex">
                <button type="button" id="sidebarCollapse" class="btn text-sidebar bg-turbo-yellow menu-toggle-btn">
                    ☰
                </button>
                <div>
                    <h6>Orders</h6>
                    <p>Home <i class="fa fa-angle-right"></i> Orders</p>
                </div>
            </div>
            <button class="btn btn-dark d-inline-block d-lg-none ml-auto" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <i class="fas fa-align-justify"></i>
            </button>

            
        </div>
    </nav>
    <!---->
    <div class="product-row normal-screen-view">
        <div class="row">
            <div class="col-sm-3" *ngFor="let product of realStocks">
                <div class="product-card">
                    <div class="flex-1">
                        <h3>{{product.type}}</h3>
                    </div>
                    <div class="flex-2">
                        <div>
                            <p class="text-center">{{product.units}}</p>
                            <p>Units</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!---->
    <div class="product-row xl-screen-view">
        <div class="row">
            <div class="col-sm-6" *ngFor="let product of realStocks">
                <div class="product-card">
                    <div class="flex-1">
                        <h3>{{product.type}}</h3>
                    </div>
                    <div class="flex-2">
                        <div>
                            <p class="text-center">{{product.units}}</p>
                            <p>Units</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <hr>

    <ul ngbNav #nav="ngbNav" class="nav-tabs">
        <li ngbNavItem>
          <a ngbNavLink>Current Orders</a>
          <ng-template ngbNavContent>
            <div class="content table-responsive table-full-width">
                <table class="table table-hover table-striped">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Order Date</th>
                                <th>Blood Type</th>
                                <th>Request Type</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let order of currentOrders | paginate: { itemsPerPage: 20, currentPage: p }; index as i">
                                <td>{{i+1}}</td>
                                <td>{{getDateString(order.tym)}}</td>
                                <td>{{order.b_type}}</td>
                                <td>{{order.Request_Type}}</td>
                                <td><button class="btn btn-thank" (click)="open(processOrderModal)">Process Order</button></td>
                            </tr>
                        </tbody>
                </table>
                <div style="display: flex; justify-content: flex-end;" class="pagination-control">
                    <div>
                        <pagination-controls (pageChange)="p = $event"></pagination-controls>                            
                    </div>
                </div>
            </div>
          </ng-template>
        </li>
        <li ngbNavItem>
          <a ngbNavLink>Order History</a>
          <ng-template ngbNavContent>
            <div class="content table-responsive table-full-width">
                <table class="table table-hover table-striped">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Order Date</th>
                                <th>Blood Type</th>
                                <th>Request Type</th>
                                <th>Dispatch</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let order of pastOrders | paginate: { itemsPerPage: 20, currentPage: p }; index as i">
                                <td>{{i+1}}</td>
                                <td>{{getDateString(order.tym)}}</td>
                                <td>{{order.b_type}}</td>
                                <td>{{order.Request_Type}}</td>
                                <td>{{order.dispatch}}</td>
                                <td><button class="btn btn-thank" [disabled]="order.thanks == '1'" (click)="open(thankDonorModal)"><i class="fa fa-thumbs-up"></i> Thank A Donor</button></td>
                                <ng-template #thankDonorModal>
                                    <div class="bag-modal">
                                        <h6>Enter bag no.</h6>
                                        <input class="form-control" [(ngModel)]="bagNo">
                                        <app-small-loader style="margin-bottom: 10px;" *ngIf="thanking"></app-small-loader>
                                        <button class="btn btn-thank" (click)="thankDonor(order.order_id)">Thank Donor</button>
                                    </div>
                                </ng-template>
                            </tr>
                        </tbody>
                </table>
                <div style="display: flex; justify-content: flex-end;" class="pagination-control">
                    <div>
                        <pagination-controls (pageChange)="p = $event"></pagination-controls>                            
                    </div>
                </div>
            </div>
          </ng-template>
        </li>
    </ul>
    <div [ngbNavOutlet]="nav"></div>


</div>

<ng-template #processOrderModal>
    <div class="process-order-modal">
        <h1><i class="fa fa-info-circle text-info"></i></h1>
        <h6>Coming Soon</h6>
    </div>
</ng-template>

