<app-loader *ngIf="loading"></app-loader>
<div class="dashboard-body">
    <div class="top-header">
        <div class="top-header-flex">
            <div class="d-flex" style="align-items: center;">
                <button type="button" id="sidebarCollapse" class="btn text-sidebar bg-turbo-yellow menu-toggle-btn">
                    ☰
                    <span></span>
                </button>
                <h6 id="sidebarCollapse">Hello User</h6>
            </div>
            <button class="btn btn-help"><i class="fa fa-question-circle"></i> Help and support</button>
        </div>
    </div>
    <div class="stats">
        <div class="row">
            <!-- <div class="col-sm-4">
                <div class="inner-card">
                    <h1>0</h1>
                    <p class="total"><b>Total Orders</b></p>
                    <p class="view">View Orders <i class="fa fa-angle-right"></i></p>
                </div>
            </div> -->
            <div class="col-sm-4">
                <!-- <div class="inner-card last-card" [routerLink]="['/main/donors']"> -->
                <div class="inner-card last-card" (click)="openPrivacyModal(privacyModal)" >
                    <h1 *ngIf="donors.length<1">0</h1>
                    <h1 *ngIf="donors.length>1">{{donors.length + 7575}}</h1>
                    <p class="total"><b>Total Donors</b></p>
                    <p class="view">View Donors <i class="fa fa-angle-right"></i></p>
                </div>
            </div>
            <div class="col-sm-4">
                <div class="inner-card" [routerLink]="['/main/inventory']">
                    <h1 *ngIf="totalStock<1">0</h1>
                    <h1 *ngIf="totalStock>1">{{totalStock + 462}}</h1>
                    <p class="total"><b>Total Stock</b></p>
                    <p class="view">View Stock <i class="fa fa-angle-right"></i></p>
                </div>
            </div>
            <div class="col-sm-4">
                <!-- <div class="inner-card last-card" [routerLink]="['/main/donors']"> -->
                <div class="inner-card last-card" (click)="openPrivacyModal(privacyModal)">
                    <h1 *ngIf="donors.length<1">0</h1>
                    <h1 *ngIf="donors.length>1">{{donors.length + 7575}}</h1>
                    <p class="total"><b>Total Donors</b></p>
                    <p class="view">View Donors <i class="fa fa-angle-right"></i></p>
                </div>
            </div>
        </div>
    </div>
    <!---->
    <div class="product-row">
        <h5 class="mb-4">Inventory</h5>
        <div class="normal-screen-view">
            <div class="row">
                <div class="col-sm-3" *ngFor="let product of realStocks">
                    <div class="product-card">
                        <div class="flex-1">
                            <h3>{{product.type}}</h3>
                        </div>
                        <div class="flex-2">
                            <div>
                                <p class="text-center">{{product.units}}</p>
                                <p>Units</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!---->
        <div class="xl-screen-view">
            <div class="row">
                <div class="col-sm-6" *ngFor="let product of realStocks">
                    <div class="product-card">
                        <div class="flex-1">
                            <h3>{{product.type}}</h3>
                        </div>
                        <div class="flex-2">
                            <div>
                                <p class="text-center">{{product.units}}</p>
                                <p>Units</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>




<ng-template #privacyModal>
    <div class="privacy-modal">
        <h1><i class="fa fa-info-circle"></i></h1>
        <h3>Data Privacy</h3>
        <p>Not allowed</p>
    </div>
</ng-template>
