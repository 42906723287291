<app-loader *ngIf="loading"></app-loader>
<div class="full-body">
  <!---->
  <nav class="navbar navbar-expand-lg navbar-light bg-light">
    <div class="container-fluid">
      <div class="d-flex">
        <button
          type="button"
          id="sidebarCollapse"
          class="btn text-sidebar bg-turbo-yellow menu-toggle-btn"
        >
          ☰
        </button>
        <div>
          <h6>Inventory Management</h6>
          <p>Home <i class="fa fa-angle-right"></i> Inventory Management</p>
        </div>
      </div>
      <button
        class="btn btn-dark d-inline-block d-lg-none ml-auto"
        type="button"
        data-toggle="collapse"
        data-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <i class="fas fa-align-justify"></i>bb
      </button>
    </div>
  </nav>
  <!---->
  <button class="btn btn-add mb-4" (click)="openAddBloodModal(addProductModal)">
    Add New Stock
  </button>
  <!---->
  <div class="product-row normal-screen-view">
    <div class="row">
      <div class="col-sm-3" *ngFor="let product of realStocks">
        <div class="product-card">
          <div class="flex-1">
            <h3>{{ product.type }}</h3>
          </div>
          <div class="flex-2">
            <div>
              <p class="text-center">{{ product.units }}</p>
              <p>Units</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!---->
  <div class="product-row normal-screen-view">
    <div class="row">
      <div class="col-sm-3">
        <div class="product-card" [routerLink]="['/main/expired']">
          <div class="flex-1">
            <h3>Expired Count</h3>
          </div>
          <div class="flex-2">
            <div>
              <p class="text-center">30</p>
              <p>Units</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!---->
  <div class="product-row xl-screen-view">
    <div class="row">
      <div class="col-sm-6" *ngFor="let product of realStocks">
        <div class="product-card">
          <div class="flex-1">
            <h3>{{ product.type }}</h3>
          </div>
          <div class="flex-2">
            <div>
              <p class="text-center">{{ product.units }}</p>
              <p>Units</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="product-row xl-screen-view">
    <div class="row">
      <div class="col-sm-6">
        <div class="product-card" [routerLink]="['/main/expired']">
          <div class="flex-1">
            <h3>Expired Count</h3>
          </div>
          <div class="flex-2">
            <div>
              <p class="text-center">30</p>
              <p>Units</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <hr />
  <!---->
  <div class="search-row">
    <div class="flex-1">
      <h6>All Donors</h6>
    </div>
    <!---->
    <div class="flex-2">
      <div class="form-row">
        <div class="col-sm-6 form-group">
          <ng-select
            [items]="filterParams"
            bindValue="value"
            bindLabel="name"
            class="formControl"
            placeholder="filter"
            (change)="filterDonorsByDropdown($event)"
          ></ng-select>
        </div>
        <div class="col-sm-6 form-group">
          <input
            type="search"
            class="form-control"
            [(ngModel)]="searchKey"
            (input)="filterDonors()"
            placeholder="filter blood by bagID"
          />
        </div>
      </div>
    </div>
  </div>
  <!---->
  <div class="content table-responsive table-full-width">
    <table class="table table-hover table-striped">
      <thead>
        <tr>
          <th>Bag ID</th>
          <th>Blood Type</th>
          <th>Genotype</th>
          <th>Date</th>
          <th>Time</th>
          <th>Expiration Date</th>
          <th>Type</th>
        </tr>
      </thead>
      <tbody>
        <tr
          *ngFor="
            let product of filteredProducts
              | paginate : { itemsPerPage: 20, currentPage: p }
          "
        >
          <td>{{ product.bag_id }}</td>
          <td>{{ product.blood_type }}</td>
          <td>{{ product.genotype }}</td>
          <td>{{ product.created_at.split(" ")[0] }}</td>
          <td>{{ product.created_at.split(" ")[1] }}</td>
          <td>{{ product.expired }}</td>
          <td>{{ product.boundedness }}</td>
        </tr>
      </tbody>
    </table>
    <div
      style="display: flex; justify-content: flex-end"
      class="pagination-control"
    >
      <div>
        <pagination-controls (pageChange)="p = $event"></pagination-controls>
      </div>
    </div>
  </div>
</div>

<ng-template #addProductModal>
  <div class="add-product-modal">
    <form [formGroup]="addProductForm">
      <h5>Add a new blood</h5>
      <div *ngIf="invalidFields">
        <small class="text-center text-danger">All fields are required</small>
      </div>
      <div class="form-group">
        <label class="form-label">Bag ID</label>
        <input class="form-control" formControlName="bag_id" />
      </div>
      <div class="form-group">
        <label class="form-label">Blood Group</label>
        <ng-select
          [items]="bloodTypes"
          bindValue="name"
          bindLabel="name"
          formControlName="blood_type"
        >
        </ng-select>
      </div>
      <div class="form-group">
        <label class="form-label">Genotype</label>
        <ng-select
          [items]="genoTypes"
          bindValue="name"
          bindLabel="name"
          formControlName="genotype"
        >
        </ng-select>
      </div>
      <div class="center-x" *ngIf="saving">
        <app-small-loader></app-small-loader>
      </div>
      <div class="center-x">
        <button class="btn btn-add" (click)="saveProduct()">Save</button>
      </div>
    </form>
  </div>
</ng-template>
