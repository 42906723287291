<app-loader *ngIf="loading"></app-loader>
<div class="full-body">
    <!---->
    <nav class="navbar navbar-expand-lg navbar-light bg-light">
        <div class="container-fluid">
            <div class="d-flex" style="align-items: center;">
                <button type="button" id="sidebarCollapse" class="btn text-sidebar bg-turbo-yellow menu-toggle-btn">
                    ☰
                </button>
                <div>
                    <h6>Screening</h6>
                    <!-- <p>Home <i class="fa fa-angle-right ml-2 mr-2"></i> Donor Management</p> -->
                </div>
            </div>
            <button class="btn btn-dark d-inline-block d-lg-none ml-auto" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <i class="fas fa-align-justify"></i>bb
            </button>

            
        </div>
    </nav>
    <!---->
    <div class="content table-responsive table-full-width">
        <table class="table table-hover table-striped">
                <thead>
                    <tr>
                        <th>Donation ID</th>
                        <th>Bag ID</th>
                        <th>Date</th>
                        <th>Time</th>
                        <th></th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let donation of donations | paginate: { itemsPerPage: 10, currentPage: p }; index as i">
                        <td>{{donation.donation_id}}</td>
                        <td>{{donation.bag_id}}</td>
                        <td>{{donation.created_at.split(' ')[0]}}</td>
                        <td>{{donation.created_at.split(' ')[1]}}</td>
                        <td>
                            <!-- <button class="btn btn-start" (click)="openTestModal(uploadTestModal, donation.donation_id, donation.bag_id)">
                               Manual Upload
                            </button> -->

                            <button class="btn btn-start" [routerLink]="['/main/upload']" [queryParams]="{donation_id: donation.donation_id, bag_id: donation.bag_id}">
                               Manual Upload
                            </button>
                        </td>
                        <td>
                            <button class="btn btn-start" (click)="openComingSoonModal(comingSoonModal)">
                               Pull SmartBag Result
                            </button>
                        </td>
                    </tr>
                </tbody>
        </table>
        <div style="display: flex; justify-content: flex-end;">
            <div>
                <pagination-controls (pageChange)="p = $event"></pagination-controls>                            
            </div>
        </div>
    </div>
</div>

<ng-template #uploadTestModal>
    <div class="upload-test-modal">
        <h6>Upload Test Result</h6>
        <div *ngIf="invalidFields">
            <small class="text-center text-danger">All fields are required</small>
        </div>
        <form [formGroup]="testForm">
            
            <h6>Medical Info <small class="text-muted">(this will not be sent to donor)</small></h6>
            <div class="form-row">
            
         
            </div>
   
            <hr>
            <h6>Donor Info <small class="text-muted">(this will be sent to donor)</small></h6>
            <div class="form-row">
                <div class="col-sm-6">
                    <div class="form-group">
                        <label class="form-label">Blood Group</label>
                        <ng-select
                            [items]="bloodTypes"
                            bindValue="name"
                            bindLabel="name"
                            formControlName="blood_type"
                        >
                        </ng-select>
                    </div>
                </div>
                <div class="col-sm-6">
                    <div class="form-group">
                        <label class="form-label">Genotype</label>
                        <ng-select
                            [items]="genoTypes"
                            bindValue="name"
                            bindLabel="name"
                            formControlName="genotype"
                        >
                        </ng-select>
                    </div>
                </div>
            </div>
           
          



            <div class="form-row mb-4 mt-4">
                <div class="col-sm-6">
                    <div class="form-check">
                        <input class="form-check-input" type="radio" name="condition" id="exampleRadios1" value="good" formControlName="condition" (change)="checkBloodStatus()" checked>
                        <label class="form-check-label" for="exampleRadios1">
                        Mark as good blood
                        </label>
                    </div>
                </div>
                <div class="col-sm-6">
                    <div class="form-check">
                        <input class="form-check-input" type="radio" name="condition" id="exampleRadios1" value="bad" formControlName="condition" (change)="checkBloodStatus()">
                        <label class="form-check-label" for="exampleRadios1">
                        Mark as bad blood
                        </label>
                    </div>
                </div>
            </div>
            
            <div *ngIf="invalidReason">
                <small class="text-center text-danger">Give a reason why the blood is bad</small>
            </div>       
            <div class="form-group" *ngIf="testForm.value.condition == 'bad'">
                <label class="form-label">Reason</label>
                <textarea class="form-control" formControlName="reason"></textarea>
            </div>
            <div class="center-x" *ngIf="saving">
                <app-small-loader></app-small-loader>
            </div>
            <div style="display: flex; justify-content: center;">
                <button class="btn btn-start mt-4" (click)="uploadTest()">Upload</button>
            </div>
        </form>
    </div>
</ng-template>

<ng-template #comingSoonModal>
    <div class="coming-soon-modal">
        <h1><i class="fa fa-info-circle text-info"></i></h1>
        <p>Coming Soon</p>
    </div>
</ng-template>