import { Component, OnInit } from '@angular/core';
import { DonationService } from 'src/app/services/donation.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-donations',
  templateUrl: './donations.component.html',
  styleUrls: ['./donations.component.scss']
})
export class DonationsComponent implements OnInit {

  donations = []
  filteredDonations = []
  currentPage = 1
  p = 1
  loading:boolean = false
  saving:boolean[] = []
  stats
  filterDate = null
  searchKey = ''


  constructor(
    private donationService:DonationService
  ) { }

  ngOnInit(): void {
    this.getDonations()
  }

  moveToTest(i, donationID, bagID){
    this.saving[i] = true
    let obj = {
      donation_id:donationID,
      bag_id:bagID
    }
    this.donationService.moveToTest(obj).subscribe(data=>{
      this.getDonations()
      Swal.fire(
        'Success',
        'Donation has been moved to test',
        'success'
      )
      this.getDonations()
      this.saving[i] = false
    },
      err=>{
        Swal.fire(
          'Oops',
          'Something went wrong',
          'error'
        )
        this.saving[i] = false
      })
  }

  getDonations(){
    this.loading = true
    this.donationService.getDonationStats().subscribe((data:any)=>{
      this.donations = []
      this.stats = {...data}
      data.donations.forEach(donation=>{
        if(donation.status != 'testing'){
          this.donations.push(donation)
        }
      })
      // this.donations = data.donations
      this.filteredDonations = [...this.donations]
      this.loading = false
      this.saving.fill(false)
    },
      err=>{
        console.log(err)
        this.loading = false
      })
  }

  filterDonationsByDate(){
    this.loading = true
    this.donationService.filterDonationsByDate({date:this.filterDate}).subscribe((data:any)=>{
      this.filteredDonations = [...data.donations]
      this.loading = false
    },
      err=>{
        this.filteredDonations = []
        this.loading = false
      })
  }

  filterBySearchKey(){
    if(!this.searchKey.trim()) {
      this.filteredDonations = [...this.donations]
      return
    }
    this.filteredDonations = this.donations.filter(x => x.bag_id.toLowerCase().includes(this.searchKey.toLowerCase()))
  }

  reset(){
    this.filteredDonations = [...this.donations]
  }

}
