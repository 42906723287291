<div class="login-full-body">
    <div class="login-page">
        <div class="text-center logo">
            <img src="../../../assets/img/lifebank5.png" width="100px" height="30px">
        </div>
        <div class="form">
          <form class="login-form" [formGroup]="loginForm">
            <div *ngIf="invalidCredentials">
              <small class="text-center text-danger">Invalid Credentials</small>
            </div>
            <input type="text" placeholder="email" formControlName="username"/>
            <input type="password" placeholder="password" formControlName="password"/>
            <div class="center-x" *ngIf="loading">
              <app-small-loader></app-small-loader>
            </div>
            <button (click)="login()">login</button>
          </form>
        </div>
    </div>
</div>
  